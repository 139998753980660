import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import config from "../config";

const QatMarker = ({ text }) => (
  <div
    style={{
      fontFamily: "Lato",
      fontSize: "18px",
      fontWeight: "500",
      color: "white",
      background: "#f06c27",
      padding: "15px 15px 15px 15px",
      display: "inline-flex",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100%",
      transform: "translate(-50%, -50%)",
    }}
  >
    {text}
  </div>
);

export default class GoogleMap extends Component {
  static defaultProps = {
    center: { lat: 33.996575, lng: -117.4068808 },
    zoom: 18,
  };

  render() {
    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: config.Places_API_KEY }}
        defaultCenter={this.props.center}
        defaultZoom={this.props.zoom}
        yesIWantToUseGoogleMapApiInternals
        options={{
          center: this.props.center,
          streetViewControl: true,
          mapTypeControl: true,
          overviewMapControl: true,
          zoom: 13,
          mapTypeId: "roadmap",
          heading: 205,
          tilt: 18,
          gestureHandling: "cooperative",
        }}
      >
        <QatMarker
          lat={33.996575}
          lng={-117.4068808}
          text={"QuickAuto Tags DMV Renewals"}
        />
      </GoogleMapReact>
    );
  }
}

/* IMPLEMENTATION

render() {
  return(
  <div style={{ width: "100%", height: "400px" }}>
    <SimpleMap />
  </div>
  );
}
*/
