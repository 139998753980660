import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
//import Media from "react-media";
import "./TermsAndConditions.css";
import Footer from "./Footer";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PhoneNumber from "react-phone-number";
import { FaPhoneAlt } from "react-icons/fa";

export default class TermsAndConditions extends Component {
	render() {
		return (
			<div>
				<Container className="tc-container">
					<Navbar
						className="tcpp-navi-bar"
						bg="light"
						variant="light"
						expand="lg"
					>
						<Row className="brand-lg container">
							<Col className="profile-img-col-lg">
								<Navbar.Brand
									as={NavLink}
									to="/"
									className="logo-element"
								>
									<Image
										src="https://cdn-assets.quickautotags.com/images/branding/Logo.QAT.png"
										className="App-logo-lg"
									/>
								</Navbar.Brand>
							</Col>
							<Col
								lg={2}
								className="contact-num contact-num-style"
							>
								<FaPhoneAlt className="phone-icon" />
								<PhoneNumber
									number=" +18887230842"
									isLinked={true}
								/>
							</Col>
						</Row>
					</Navbar>
					<article className="tc-p">
						Thank you for using our services (“Services”). The
						Services are provided by QuickAutoTags
						("QuickAutoTags"), a California Department of Motor
						Vehicles ("DMV") authorized BPA (Business Partner
						Automation) provider under CVC 1685, license number
						93394. QuickAutoTags.com is not owned or operated by any
						government agency. QuickAutoTags.com ("Website") and our
						Services are offered to you ("User", "Your", "You"),
						conditioned on your acceptance without modification, of
						the following terms, conditions, and notices contained
						in this agreement ("Terms of Use"). Your use of this
						Website or any of our Services, via telephone, internet,
						or email, constitutes your full agreement to all such
						terms, conditions, and notices in effect at such time,
						offered through QuickAutoTags, it's parents,
						subsidiaries, or affiliates, collectively. Please read
						them carefully. Throughout this site, the terms
						"QuickAutoTags, "QuickAutoTags.com", "we", "us", and
						"our" refers to QuickAutoTags. The term "Advertiser"
						refers to businesses which we may choose to display,
						promote, list or advertise on our site, including and
						not limited to, private BPA services, automotive
						dealerships and vehicle service providers. We may amend
						these Terms of Use at any time by posting a revised
						version of these Terms of Use.
						<h4 className="tc-heading">1. Offer Terms</h4>
						<p>
							<strong>1.1.</strong> By using our Services you
							agree to have Your vehicle registration processed
							using the QuickAutoTags vehicle registration
							service.
						</p>
						<p>
							<strong>1.2.</strong> You agree to pay for any and
							all services or products that you purchase by using
							our Services and that we may charge the credit card
							you provide us for them.
						</p>
						<p>
							<strong>1.3.</strong> All sales for our Services are
							final and non-refundable. Registration fees paid to
							the DMV can not be reversed or cancelled.
						</p>
						<p>
							<strong>1.4.</strong> Chargebacks will be considered
							civil theft. Any chargeback will be prosecuted to
							the full extent of the law. QuickAutoTags will
							report all chargebacks to the appropriate
							authorities as these may be construed as theft of
							services and chargeback fraud.
							<h4 className="tc-heading">
								2. Registration Service Fees
							</h4>
						</p>
						<p>
							<strong>2.1.</strong> The Website allows you to
							ability to make multiple service requests within one
							transaction. These fees apply to each vehicle for
							which service has been requested. You hereby agree
							to be charged the following fees to renew your
							vehicle registration, request registration
							replacement, or transfer your vehicle title through
							QuickAutoTags:
						</p>
						<p>
							<strong>2.1.1.</strong> "Service" fees. Service fees
							are fees charged by QuickAutoTags for utilizing our
							Services. Our service fees are as follows:
							<ul>
								<li>Registration renewal: $30</li>
								<li>Transfer of title (CA vehicles): $56</li>
								<li>Revived Salvage/Junk: $75</li>
								<li>Legal Transfers: $30</li>
								<li>Lien Sales (Short): $70</li>
								<li>Lien Sales (Long): $100</li>
								<li>
									Replacement Stickers, Registration Cards, or
									Plates: $30
								</li>
								<li>Diligent Efforts: $200 Minimum</li>
								<li>Items Requiring DMV Visits: $75</li>
								<li>
									VIN verification: $25 (On-Site Inspection)
								</li>
							</ul>
							You understand and agree that once we receive and
							verify your order we are entitled to our service
							fees. You further agree that these fees are in
							addition to any DMV fees, penalties, or taxes which
							you may owe the California Department of Motor
							Vehicle (CVC 1685). All service fees are final and
							non-refundable. Title transfer service fee will
							apply upon review of uploaded document(s). Balance
							of title transfer fees will be charged to your
							credit card after your document(s) are received by
							QuickAutoTags.
						</p>
						<p>
							<strong>2.1.2.</strong> "DMV" and "BPA" fees. DMV
							fees ("Registration Fees") are fees which are
							assessed and charged by the California Department of
							Motor Vehicles in order to register your vehicle,
							transfer your vehicle title, or replace your vehicle
							registration. You agree that we will collect and
							forward these fees, including DMV BPA fees according
							to CVC 1685, and any late fees, parking violations,
							penalties, etc. You understand and agree that there
							is absolutely no reversal of DMV fees, and as such,
							DMV fees are not refundable. You agree to pay us in
							full all DMV fees which we pay on your behalf. Any
							over-payment or double payment made to the DMV
							directly by you will require you request and file
							for a refund from the California Department of Motor
							Vehicles following the refund procedures set forth
							by them. You may contact the DMV at (800) 777-0133.
						</p>
						<p>
							<strong>2.1.3.</strong> "Credit Card Processing"
							fees. QuickAutoTags charges a credit card processing
							fee of 3.75% on all amounts paid to the Department
							of Motor Vehicles. These transactions include but
							are not limited to, orders which require cardholder
							verification/identification, all title transfers,
							commercial vehicle registration, any parking or toll
							violation payment and/or late penalty. Depending on
							the circumstances of your transaction, this fee
							requirement may or may not be displayed to you at
							checkout. If your transaction requires that we
							collect a credit card processing fee after checkout,
							we will contact you to obtain your authorization to
							collect this fee and complete your order.
						</p>
						<p>
							<strong>2.1.4.</strong> "Shipping and Handling"
							fees. You will be charged shipping and handling fees
							based on the shipping service you request. Our
							shipping and handling fees are as follows:
							<ul>
								<li>First-Class USPS shipping $1.95</li>
								<li>
									Next-day before 4:00pm (PST) delivery $14.95
								</li>
								<li>
									Next-day before 10:00am (PST) delivery
									$34.95
								</li>
								<li>
									Digital registration card copy (e-Copy) FREE
								</li>
							</ul>
							<h4 className="tc-heading">
								3. Processing and Shipping
							</h4>
						</p>
						<p>
							<strong>3.1</strong>. "Business Hours". We process
							registration orders Monday through Friday, excluding
							holidays. Orders received after 10:00pm (PST)
							weekdays are processed the next business day.
							QuickAutoTags may not be able to process orders when
							the DMV is closed or when DMV systems are not
							available. You agree that QuickAutoTags is not
							responsible for any late fees or penalties or both
							which you may incur.
						</p>
						<p>
							<strong>3.2.</strong> You agree that we are not
							responsible for incorrect information provided to us
							by you or by others on your behalf. In the event
							that corrected information or additional information
							is required to complete your vehicle registration
							order, including but not limited to, satisfactory
							proof of ownership, confidential records release,
							proof of current vehicle liability insurance, valid
							vehicle smog inspection certification on file with
							the DMV, or payment of outstanding parking
							ticket(s), QuickAutoTags will contact you and
							request additional items. You may be required to
							submit original, facsimile, or digital copy of the
							items as needed, either directly to the DMV or
							QuickAutoTags. You agree that if the DMV or
							QuickAutoTags requires additional items to complete
							your registration order we may be unable to post
							your registration fees with the DMV. This may cause
							you to accrue late fees or penalties or both for
							which we are not responsible. When QuickAutoTags is
							allowed to post your fees, the DMV will issue a
							notice of report of deposit of fees (RDF) which we
							will send you in electronic format. You agree that
							any delay in providing requested items may result in
							additional fees or penalties or both assessed by the
							DMV. DMV fees displayed on this website are only
							valid on the date and at the time which the fees are
							electronically requested. QuickAutoTags is not
							responsible for any additional fees or penalties or
							both assessed by the DMV at any time or for any
							reason.
						</p>
						<p>
							<strong>3.3.</strong> Guaranteed delivery means that
							if your order is returned to QuickAutoTags we will
							make every effort to contact you and resend your
							order to the mailing address which you provided us
							initially or to a new mailing address you provide us
							during our communication with you. We may require
							verification of any shipping address submitted to us
							by you which differs from Your mailing address on
							file with the California Department of Motor
							Vehicles. QuickAutoTags is not responsible for any
							delays on the part of any courier service we use to
							ship your order. You agree we are not responsible
							for any lost, damaged or mutilated items, and we are
							not responsible for non-delivery of your order. One
							(1) free replacement order might be available to you
							if you do not receive your order and contact
							QuickAutoTags within 60 days but not before than 21
							days of processing.
						</p>
						<p>
							<strong>3.4.</strong> Next-day delivery is not
							available on weekends and holidays and can not be
							made to a P.O. Box. All next-day orders are required
							to be submitted before 3:00pm, weekdays. Next-day
							shipping offers tracking and may require signature
							upon delivery.
						</p>
						<p>
							<strong>3.5.</strong> Some orders may not be able to
							be processed due to suspensions placed on the
							vehicle registration by the State of California.
							These can include suspensions due to emission system
							recalls, parking/toll violations, lapse of insurance
							coverage, smog certificate requirements, etc. For
							these situations you will receive an email with
							information on how to resolve the issue through your
							local DMV branch office.
						</p>
						<p>
							<h4 className="tc-heading">
								4. Electronic "E-Signature", Consent, and
								Communication
							</h4>
						</p>
						<p>
							<strong>4.1.</strong> In accordance with The
							Electronic Signatures in Global and National
							Commerce Act (ESIGN) You agree that your use of a
							keypad, keyboard, mouse or other device to select an
							item, button, icon or similar act/action while using
							any Service offered on this Website or in accessing
							or making any transactions regarding any agreement,
							acknowledgement, consent, terms, disclosures,
							service requests, or conditions constitutes your
							Electronic Signature ("E-Signature"), acceptance and
							agreement as if actually signed by you in writing.
							You agree that no certification authority or other
							third party verification is necessary to validate
							your E-signature; and that the lack of such
							certification or third party verification will not
							in any way affect the enforceability of your
							signature or resulting service request, agreement,
							or contract between you, QuickAutoTags, or the DMV.
							Furthermore you consent and agree that your
							"E-Signature" will be used by us or the California
							DMV or both on any application, form, document,
							disclosure or agreement necessary to process Your
							vehicle registration transaction. Upon Your request,
							you are entitled to receive copies of any or all
							electronically/digitally signed items used in
							connection with Your registration transaction. You
							have the option to not have Your signature(s)
							accepted electronically/digitally on any item by
							completing and submitting such item in its original
							paper copy, digital upload or facsimile copy to
							QuickAutoTags or the DMV or both.
						</p>
						<p>
							<strong>4.2.</strong> You agree that we may send you
							any notice or other communication regarding our
							Services and this Website in electronic form. In
							order to receive electronic notices or
							communications you must have a Windows or Macintosh
							operating system based personal computer connected
							to the internet and installed with an Internet
							browser. We will send notices and communications to
							the email address which you provide to us during
							Your use of any Services on this Website. The
							delivery of any electronic notices or other
							communications from us will be considered effective
							regardless of whether you actually receive the
							notice or communication, or whether you read such
							notice or communication when you receive it.
						</p>
						<p>
							<h4 className="tc-heading">5. Change Of Address</h4>
						</p>
						<p>
							<strong>5.1.</strong> You hereby agree and
							understand that QuickAutoTags cannot change Your
							physical address on file with the California
							Department of Motor Vehicles by your request. We
							will ship your order to the mailing address which
							you provide us but this will in no way change,
							update, or modify the mailing address on file with
							the DMV. We are not responsible for non-delivery of
							any item now or in the future. To change your
							address on file with the DMV you must contact your
							local DMV office. While processing your registration
							order through QuickAutoTags the DMV may require that
							we update your vehicle registration mailing address.
							Should this occur you will need to submit an address
							verification document signed by the registered owner
							in order to continue your transaction with us.
							Follow this link to view a list of acceptable
							address verification documents.
						</p>
						<p>
							<h4 className="tc-heading">
								6. Third-Party Links and Services
							</h4>
						</p>
						<p>
							<strong>6.1.</strong> This Website may contain links
							to third-party Websites, advertisers, services, or
							other events or activities that are not owned or
							controlled by QuickAutoTags (collectively, "Third
							Party(ies)"). QuickAutoTags does not endorse or
							assume any responsibility for any such Third Party
							sites, information, materials, products, or
							services. If you access a Third Party Website from
							this Website or use a Third Party service, you do so
							at your own risk, and you understand that this
							Agreement and the QuickAutoTags Privacy Policy do
							not apply to your use of such Third Party sites or
							services. You expressly relieve QuickAutoTags from
							any and all liability arising from your use of any
							Third Party Website, service, or content.
							Additionally, your dealings with or participation in
							promotions of Third Parties found on this Website,
							including payment and delivery of services, and any
							other terms (such as warranties) are solely between
							you and such Third Parties. You agree that
							QuickAutoTags shall not be responsible for any loss
							or damage of any sort relating to your dealings with
							such Third Parties.
						</p>
						<p>
							<h4 className="tc-heading">7. Advertising Fees</h4>
						</p>
						<p>
							<strong>7.1.</strong> "Advertiser" fees.
							QuickAutoTags charges advertising fees to
							third-party BPA members ("Advertiser") who select to
							advertise their services on the Website. Advertiser
							fees depend on the coverage area selected by the
							Advertiser and are billed on a monthly basis and
							according to the ad plan selected by the Advertiser.
							The Advertiser agrees that QuickAutoTags will charge
							the credit card they provide us for advertising on
							the Website as expressed in their agreement with
							QuickAutoTags.
						</p>
						<p>
							<h4 className="tc-heading">
								8. Advertiser Term, Termination and Cancellation
							</h4>
						</p>
						<p>
							<strong>8.1.</strong> Advertiser hereby grants to
							QuickAutoTags a nonexclusive, limited, revocable
							license to:
							<ol>
								<li>
									market, display, perform, copy, transmit,
									and promote the Advertisement(s) in
									connection with its obligations hereunder;
								</li>
								<li>
									market display, perform, copy, transmit, and
									promote the Advertisement(s) to Third-Party
									Providers in connection with its obligations
									hereunder; and
								</li>
								<li>
									grant Third-Party Providers the right to
									market, display, perform, copy, transmit,
									and promote the Advertisement(s) on the
									Internet.
								</li>
							</ol>
						</p>
						<p>
							<strong>8.2.</strong> The initial term of this
							agreement ("Advertiser Agreement") is normally for
							one year (12 months) unless we agree otherwise.
							Unless you notify us to the contrary, this Agreement
							shall automatically extend for additional one year
							terms (each an "Additional Term" and, with the
							Initial Term, the "Term"). Your hereby authorize us
							to charge your credit card for any charges, if
							applicable, in the Additional Term.
						</p>
						<p>
							<strong>8.3.</strong> We may terminate or suspend
							service or your account or any other provision of
							services to you at our discretion without
							explanation and notice. If you wish to terminate
							this Agreement, you are solely responsible for
							following proper termination procedures.
							Cancellation may result in the immediate deletion of
							any content that you have submitted to
							QuickAutoTags. All provisions of this agreement
							that, by their nature, shall survive termination,
							including, without limitation, warranty disclaimers,
							ownership provisions, indemnity and limitations of
							liability.
						</p>
						<p>
							<strong>8.4.</strong> Advertiser Agreement will
							continue for the term set forth in the Terms of Use,
							provided that either party may terminate this
							Agreement upon thirty (30) business days prior
							written notice.
						</p>
						<p>
							<h4 className="tc-heading">
								9. Password Registration and Protection
							</h4>
						</p>
						<p>
							<strong>9.1.</strong> Parts of this Website are
							restricted and require a user identification code
							("User ID") and a user password for access.
							Unauthorized use of such parts of this Website is
							strictly prohibited. Actual or attempted
							unauthorized use of or access to such areas may
							result in criminal or civil prosecution or both.
							Attempts to access such areas without authorization
							may be monitored and recorded, and any information
							obtained may be given to law enforcement entities in
							connection with any investigation or prosecution of
							possible criminal activity on the Website. If you
							are not an authorized user of such areas or do not
							consent to continued monitoring, you should not
							attempt to access such areas. You understand that we
							may suspend or terminate your use of your user ID
							and password at any time for any or no reason. If
							access to your account is suspended or terminated,
							you acknowledge that you have no right to access
							this site. Notwithstanding such suspension or
							termination, the remainder of these Terms of Use
							shall remain in effect.
						</p>
						<p>
							<h4 className="tc-heading">
								10. Trademarks And Copyrights
							</h4>
						</p>
						<p>
							<strong>10.1.</strong> All contents of this site,
							including but not limited to any text, software,
							files, graphics, photos, images, design, video, and
							data found on this site (collectively, the
							"Materials"), are the property of and owned by
							QuickAutoTags, and are protected by copyright,
							trademark and other laws of the United States.
							Except in limited circumstances as described below,
							use of the Materials other than viewing the site,
							including but not limited to the reproduction,
							copying, modification, display, adaptation,
							publication, translation, public performance,
							reverse engineering, transfer, transmission,
							broadcast, distribution, licensing, sale or gift in
							whole or in part, or the creation of derivative
							works from such Materials, is expressly prohibited.
						</p>
						<p>
							<h4 className="tc-heading">
								11. Use of Material From This Website
							</h4>
						</p>
						<p>
							<strong>11.1.</strong> In circumstances where
							certain areas of the site expressly provide that you
							are authorized to use Materials from the site
							outside of the site, your authorized use is limited
							solely to your own personal use and that in the
							scope of which you have entered into an agreement
							with QuickAutoTags as an advertiser or provider of
							authorized services by QuickAutoTags. Your use of
							the Materials prohibits you from displaying,
							publicly performing, publishing, transmitting,
							distributing, broadcasting, or otherwise making
							available to any third party such Materials, even
							without charge, or authorizing, encouraging,
							providing or allowing any of the Materials used or
							obtained by you to be used, reproduced, copied,
							modified, displayed, adapted, published, translated,
							publicly performed, reverse engineered, transferred,
							transmitted, broadcast, distributed, licensed, sold,
							given or otherwise used by any third party.
							QuickAutoTags reserves every right to further
							restrict or revoke your authorization to use the
							Materials and to bring whatever legal action it
							deems necessary to enforce these Terms of Use.
						</p>
						<p>
							<h4 className="tc-heading">
								12. Material You Submit
							</h4>
						</p>
						<p>
							<strong>12.1.</strong> You may not use a false email
							address or impersonate any person or entity. You
							acknowledge that you are responsible for any
							submission ("Content") you make, including the
							legality, reliability, appropriateness, and
							originality. You may not upload to, distribute or
							otherwise publish through this Website any content
							that (i) is defamatory, libelous, fraudulent,
							obscene or threatening; infringing on third parties'
							intellectual property rights, including but not
							limited to trademark, right of publicity, copyright
							or patent rights, right of privacy, trade secret or
							other proprietary rights; or harassing, abusive,
							illegal or otherwise objectionable, (ii) may
							constitute or encourage a criminal offense, violate
							the rights of any party or otherwise give rise to
							liability or violate any applicable law, statute,
							ordinance or regulation, or (iii) may contain
							software viruses, spyware, Trojan horses, political
							campaigning, chain letters, mass mailings,
							commercial advertising or any form of "spam."
						</p>
						<p>
							<strong>12.2.</strong> By submitting Content you
							grant QuickAutoTags unlimited, worldwide, perpetual,
							license and right to publish, display, use, publicly
							perform, adapt and modify the Content, or any
							component thereof, with or without attribution, as
							well as to use your name, likeness, and other
							personally identifying indicia ("Name and
							Likeness"), in any way, including for advertising
							and promotional purposes, in any and all media,
							without limitation at to time or territory, and
							without additional consideration to you. You
							acknowledge that you have no right to review or
							approve how your Content or Name and Likeness is
							used.
						</p>
						<p>
							<h4 className="tc-heading">13. Disclaimers</h4>
						</p>
						<p>
							<strong>13.1.</strong> The material contained on
							this Website may contain inaccuracies and
							typographical errors. You agree that we are not
							liable for content that is provided by others. We
							have no duty to prescreen content that is submitted
							by third parties (including you) to this Website,
							but we have the right to refuse to post or to edit
							submitted content. We reserve the right to remove
							content for any reason, but we are not responsible
							for any failure or delay in removing such material.
							Changes are periodically made to the Website and may
							be made at any time. YOUR USE OF THIS Website IS AT
							YOUR SOLE RISK. THIS Website IS PROVIDED ON AN "AS
							IS" AND "AS AVAILABLE" BASIS. WE RESERVE THE RIGHT
							TO RESTRICT OR TERMINATE YOUR ACCESS TO THIS Website
							OR ANY FEATURE OR PART THEREOF AT ANY TIME. TO THE
							FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW,
							NEEDTAGS EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY
							KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT
							LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY
							AND FITNESS FOR A PARTICULAR PURPOSE AND ANY
							WARRANTIES THAT MATERIALS ON THIS Website ARE
							NONINFRINGING; THAT ACCESS TO THIS Website WILL BE
							UNINTERRUPTED OR ERROR-FREE; THAT THIS Website WILL
							BE SECURE; THAT THIS Website OR THE SERVER THAT
							MAKES THIS Website AVAILABLE WILL BE VIRUS-FREE; OR
							THAT INFORMATION ON THIS Website WILL BE COMPLETE,
							ACCURATE, RELIABLE OR TIMELY. WHILE ACCESSING THIS
							Website AND WHEN DOWNLOADING ANY MATERIALS FROM THIS
							Website, YOU DO SO AT YOUR OWN DISCRETION AND RISK.
							YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO
							YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS
							FROM ACCESSING OR DOWNLOADING OF ANY SUCH MATERIALS.
							NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
							OBTAINED BY YOU FROM NEEDTAGS OR THROUGH OR FROM
							THIS Website SHALL CREATE ANY WARRANTY OF ANY KIND.
							NEEDTAGS DOES NOT MAKE ANY WARRANTIES OR
							REPRESENTATIONS REGARDING THE USE OF THE MATERIALS
							ON THIS Website IN TERMS OF THEIR COMPLETENESS,
							CORRECTNESS, ACCURACY, ADEQUACY, USEFULNESS,
							TIMELINESS, RELIABILITY OR OTHERWISE.
						</p>
						<p>
							<strong>13.2.</strong> IN CERTAIN STATES, THE LAW
							MAY NOT PERMIT THE DISCLAIMER OF WARRANTIES, SO THE
							ABOVE DISCLAIMER MAY NOT APPLY TO YOU.
						</p>
						<p>
							<h4 className="tc-heading">
								14. Limitation Of Liability
							</h4>
						</p>
						<p>
							<strong>14.1.</strong> TO THE FULLEST EXTENT
							PERMITTED UNDER APPLICABLE LAW, YOU UNDERSTAND AND
							AGREE THAT NEITHER NEEDTAGS NOR ANY OF ITS
							AFFILIATES OR THIRD-PARTY CONTENT PROVIDERS SHALL BE
							LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
							SPECIAL, EXEMPLARY, CONSEQUENTIAL, PUNITIVE OR ANY
							OTHER DAMAGES RELATING TO OR RESULTING FROM YOUR USE
							OF OR INABILITY TO USE THIS Website OR ANY OTHER
							Website YOU ACCESS THROUGH A LINK FROM THIS Website
							OR FROM ANY ACTIONS WE TAKE OR FAIL TO TAKE AS A
							RESULT OF EMAIL MESSAGES YOU SEND US. THESE INCLUDE
							DAMAGES FOR ERRORS, OMISSIONS, INTERRUPTIONS,
							DEFECTS, DELAYS, COMPUTER VIRUSES, YOUR LOSS OF
							PROFITS, LOSS OF DATA, UNAUTHORIZED ACCESS TO AND
							ALTERATION OF YOUR TRANSMISSIONS AND DATA, AND OTHER
							TANGIBLE AND INTANGIBLE LOSSES.
						</p>
						<p>
							<strong>14.2.</strong> THIS LIMITATION APPLIES
							REGARDLESS OF WHETHER THE DAMAGES ARE CLAIMED UNDER
							THE TERMS OF A CONTRACT, AS THE RESULT OF NEGLIGENCE
							OR OTHERWISE ARISE OUT OF OR IN CONNECTION WITH THE
							USE, INABILITY TO USE OR PERFORMANCE OF THE
							INFORMATION, SERVICES, PRODUCTS OR MATERIALS
							AVAILABLE FROM THIS Website, AND EVEN IF WE OR OUR
							REPRESENTATIVES HAVE BEEN NEGLIGENT OR HAVE BEEN
							ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
						</p>
						<p>
							<strong>14.3.</strong> BECAUSE SOME JURISDICTIONS DO
							NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED
							WARRANTY LASTS, OR THE EXCLUSION OR LIMITATION OF
							LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES,
							ALL OR A PORTION OF THE ABOVE LIMITATION MAY NOT
							APPLY TO YOU.
						</p>
						<p>
							<h4 className="tc-heading">
								15. Jurisdiction & Enforcement
							</h4>
						</p>
						<p>
							<strong>15.1.</strong> The laws of California govern
							these Terms of Use and your use of this Website, and
							you irrevocably consent to the jurisdiction of the
							courts located in the California for any action to
							enforce these Terms of Use. We recognize that it is
							possible for you to obtain access to this Website
							from any jurisdiction in the world, but we have no
							practical ability to prevent such access. This
							Website has been designed to comply with the laws of
							California and of the United States. If any material
							on this Website, or your use of this Website, is
							contrary to the laws of the place where you are when
							you access it, this Website is not intended for you,
							and we ask you not to use this Website. You are
							responsible for informing yourself of the laws of
							your jurisdiction and complying with them.
						</p>
						<p>
							<strong>15.2.</strong> Any disputes resulting from
							your use of our Website shall be construed and
							enforced under the laws of the State of California.
							Visitors hereby submit to the jurisdiction of the
							courts of Los Angles County, California, and waive
							any objection to venue with respect to actions
							brought in such courts. In any dispute, the
							prevailing party shall be entitled to recover its
							reasonable attorney’s fees and cost from the
							non-prevailing party. If any provision of these
							Terms of Use is held invalid, illegal, or
							unenforceable, the validity, legality, or
							enforceability of the remaining provisions shall in
							no way be affected or impaired thereby.
						</p>
						<p>
							<strong>15.3.</strong> Attorneys Fees and Costs: The
							parties shall be entitled to recover, in addition to
							costs, disbursements allowed by law, pre-litigation
							costs, reasonable attorney’s fees and costs in
							connection with enforcement of this agreement, and
							such fees shall be awarded to the prevailing party.
							Invalidation of any one of the covenants or terms of
							this Agreement, by judgment of a court, shall not
							affect any of the other provisions of this Agreement
							which shall remain in full force and effect.
						</p>
						<p>
							<h4 className="tc-heading">16. Indemnity</h4>
						</p>
						<strong>16.1.</strong> You agree to defend, indemnify,
						and hold harmless QuickAutoTags, its officers,
						directors, employees, and agents, from and against any
						and all claims, damages, obligations, losses,
						liabilities, costs or debt, and expenses (including but
						not limited to attorney's fees) arising from:
						<ol>
							<li>
								Your use of and access to the Website and
								Services;
							</li>
							<li>
								Your violation of any term of these Terms of
								Use;
							</li>
							<li>
								Your violation of any third party right,
								including without limitation any copyright,
								property, or privacy right; or (iv) any claim
								that any of your content caused damage to a
								third party. This defense and indemnification
								obligation will survive these Terms of Use and
								your use of the QuickAutoTags Services.
							</li>
						</ol>
						<p>
							<strong>16.2.</strong> You also agree that you have
							a duty to defend us against such claims, and we may
							require you to pay for an attorney(s) of our choice
							in such cases. You agree that this indemnity extends
							to requiring you to pay for our reasonable
							attorneys' fees, court costs, and disbursements. In
							the event of a claim such as one described in this
							paragraph, we may elect to settle with the
							party/parties making the claim and you shall be
							liable for the damages as though we had proceeded
							with a trial.
						</p>
						<p>
							<h4 className="tc-heading">17. Force Majeure</h4>
						</p>
						<p>
							<strong>17.1.</strong> Neither party shall be liable
							for service interruptions, delays, failure to
							perform, damages, losses or destruction, or
							malfunction of any consequence thereof caused or
							occasioned by, or due to fire, water, flood, the
							elements, acts of God, war, civil disturbances,
							explosions, governmental actions, shortages of
							equipment or supplies, unavailability of
							transportation, acts or omissions of third parties,
							or any other cause beyond the effected party’s
							reasonable control. The party so delayed or
							prevented from performing shall provide prompt
							notice of such event to the other party and shall
							exercise good faith efforts to remedy any such cause
							of delay or cause preventing performance.
						</p>
						<p>
							<h4 className="tc-heading">
								18. Amendment and Modification to Terms of Use
							</h4>
						</p>
						<p>
							<strong>18.1.</strong> We reserve the right, in our
							sole discretion, to change these Terms of Use at any
							time by posting revised terms on this site. It is
							your responsibility to check periodically for any
							changes we may make to these Terms of Use. Your
							continued use of this site following the posting of
							changes to these terms or other policies means you
							accept the changes. No change, amendment,
							modification, termination or attempted waiver by you
							of any of the provisions set forth herein shall be
							binding unless made in writing and signed by a duly
							authorized representative of QuickAutoTags and no
							promise, representation, inducement or statement of
							intention has been made by either party which is not
							embodied herein.
						</p>
						<p>
							<h4 className="tc-heading">19. Notice</h4>
						</p>
						<p>
							<strong>19.1.</strong> All notices, requests,
							demands, and other communications hereunder shall be
							in writing and shall be deemed given at the time
							such communication is sent by registered or
							certified mail (return receipt requested), or
							recognized national overnight courier service, or
							delivered personally, to the following address (or
							other address as shall be specified by like notice
							and made to the attention of both the CEO and Legal
							Team):
						</p>
						<p>QuickAutoTags</p>
						<p>
							<strong>Attn: Legal Team</strong>
						</p>
						<p>5586 Mission Blvd.</p>
						<p>Riverside, CA 92509</p>
						<h4 className="tc-heading">20. Survival</h4>
						<p>
							<strong>20.1.</strong> Any obligations which
							expressly or by their nature are to continue after
							termination, cancellation, or expiration of the
							Agreement shall survive and remain in effect after
							such happening.
						</p>
					</article>
				</Container>
				<section>
					{/* Need the Footer Menu to display new features */}
					<Footer />
				</section>
			</div>
		);
	}
}
