import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";

import Amplify from "aws-amplify";
import config from "./config";

import * as serviceWorker from "./serviceWorker";

Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID,
	},
	Storage: {
		region: config.s3.REGION,
		bucket: config.s3.BUCKET,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
	},
	API: {
		endpoints: [
			{
				// NOTE: The API "name" is critical and used by aws-amplify
				//       when an amplify API.post() method where the first
				//       argument is the name of this API field!!!
				name: "case-records",
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION,
			},
			{
				// user
				name: "users",
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION,
			},
			{
				// kb articles
				name: "knowledge-base",
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION,
			},
			{
				// avrs
				name: "avrs-utilities",
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION,
			},
			{
				// accounting
				name: "payments",
				endpoint: config.apiGateway.URL,
				region: config.apiGateway.REGION,
			},
			{
				// avrs
				name: "avrs-transactions",
				endpoint: config.apiGateway.AVRS_URL,
				region: config.apiGateway.REGION,
			},
		],
	},
});

ReactDOM.render(
	<Router>
		<App />
	</Router>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
