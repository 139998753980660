import React, { Component } from "react";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Media from "react-media";
import "./Footer.css";

export default class Footer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
		};
	}

	render() {
		return (
			<div className="ftr-menu">
				<Container className="footer-menu">
					<Media
						query="(min-width: 319px) and (max-width: 568px)"
						render={() => (
							<footer className="ftr">
								<Row className="copyright">
									Powered with
									<em style={{ color: "#434343" }}>
										{" "}
										..
									</em>{" "}
									<Image
										src="https://cdn-assets.quickautotags.com/images/content/Lightning.png"
										className="ftr-icon-xs"
										responsive="true"
									/>
									<em style={{ color: "#434343" }}>..</em> by{" "}
									<a
										href="https://www.upwork.com/ag/nativestack"
										className="ftr-link"
									>
										<em style={{ color: "#434343" }}>. </em>{" "}
										NativeStack Engineering{" "}
									</a>
								</Row>
							</footer>
						)}
					/>
					<Media
						query="(min-width: 567px) and (max-width: 900px)"
						render={() => (
							<footer className="ftr">
								<Row className="copyright">
									Powered with
									<em style={{ color: "#434343" }}>
										{" "}
										..
									</em>{" "}
									<Image
										src="https://cdn-assets.quickautotags.com/images/content/Lightning.png"
										className="ftr-icon-md"
										responsive="true"
									/>
									<em style={{ color: "#434343" }}>..</em> by{" "}
									<a
										href="https://www.upwork.com/ag/nativestack"
										className="ftr-link"
									>
										<em style={{ color: "#434343" }}>. </em>{" "}
										NativeStack Engineering{" "}
									</a>
								</Row>
							</footer>
						)}
					/>
					<Media
						query="(min-width: 901px)"
						render={() => (
							<footer className="ftr">
								<Row className="copyright">
									Powered with
									<em style={{ color: "#434343" }}>
										{" "}
										..
									</em>{" "}
									<Image
										src="https://cdn-assets.quickautotags.com/images/content/Lightning.png"
										className="ftr-icon"
										responsive="true"
									/>
									<em style={{ color: "#434343" }}>..</em> by{" "}
									<a
										href="https://www.upwork.com/ag/nativestack"
										className="ftr-link"
									>
										<em style={{ color: "#434343" }}>. </em>{" "}
										NativeStack Engineering{" "}
									</a>
								</Row>
							</footer>
						)}
					/>
				</Container>
			</div>
		);
	}
}

// eslint-disable-next-line
