import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router";
import PhoneNumber from "react-phone-number";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Navbar from "react-bootstrap/Navbar";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Nav from "react-bootstrap/Nav";
import Media from "react-media";
import { Auth } from "aws-amplify";
import Routes from "./Routes";
import "./App.css";

import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      userAccessRole: "",
    };
  }

  // TODO: dev branch change just to get on cicd for now
  // TODO: test branch change just to get on cicd for now
  // TODO: demo branch change just to get on cicd for now
  // Use componentDidMount() to load the user session with Amplify
  async componentDidMount() {
    try {
      // returns a promise, rest of app will wait for this response
      // from amplify auth with cognito
      //await Auth.currentSession();
      // if 200 OK from above with session info then execute next
      this.userHasAuthenticated(false);

      // if OK pull role from cognito custom attribute and store in state
      //this.userHasPermission("admin");
    } catch (err) {
      if (err !== "No current user") {
        // eslint-disable-next-line
        alert("Test");
      }
    }
    // loading user session is asyn process, we need to make sure
    // that our app does not change state when it first loads
    // we wait to render until isAuthenticating is false
    this.setState({
      isAuthenticating: false,
    });
  }

  /*
  userHasPermission = accessRole => {
    this.setState({
      userAccessRole: accessRole
    });
  }
*/

  userHasAuthenticated = (authenticated) => {
    this.setState({
      isAuthenticated: authenticated,
    });
  };

  // eslint-disable-next-line
  handleLogout = async (event) => {
    // returns a promise that will clear user session for logout
    await Auth.signOut();
    this.userHasAuthenticated(false);
    // use history.push from react-router4 to redirect
    // user during login flow
    this.props.history.push("/signin");
  };

  render() {
    const childProps = {
      // below is authentication provided by cognito
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      // Below is permissions available and passed as props
      // authorization!!
      //userAccessRole: this.state.userAccessRole,
      //userHasPermission: this.userHasPermission
      vWidget: true,
    };

    return (
      !this.state.isAuthenticating && (
        <Container>
          <Media
            query="(min-width: 300px) and (max-width: 899px)"
            render={() => (
              <div className="App container">
                <Routes childProps={childProps} />
              </div>
            )}
          />

          <Media
            query="(min-width: 900px)"
            render={() => (
              <div className="App container">
                <Routes childProps={childProps} />
              </div>
            )}
          />
        </Container>
      )
    );
  }
}

// export with a HOC to give app access to router props
// access the history object's props and the closest
// <Routes>'s match withRouter().
export default withRouter(App);
//export default App;
