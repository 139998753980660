import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Media from "react-media";
import Footer from "./Footer";
import UiLoadBtn from "./UiLoadBtn";
import PhoneNumber from "react-phone-number";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Navbar from "react-bootstrap/Navbar";
import "./HomeLandingPage.css";
import GoogleMap from "../component-lib/GoogleMap";
import AvrsModal from "../containers/AvrsModal";
import AvrsFormPrimary from "../containers/AvrsFormPrimary";
import AvrsFormSecondary from "../containers/AvrsFormSecondary";

import { Parallax, ParallaxLayer } from "react-spring/renderprops-addons";
import { FaPhoneAlt, FaRegCheckSquare } from "react-icons/fa";

/* global google */

const url = (file, dir, wrap = false) =>
	`${
		wrap ? "url(" : ""
	}https://cdn-assets.quickautotags.com/images/${dir}/${file}${
		wrap ? ")" : ""
	}`;

export default class HomeLandingPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			eventLists: [],
			usr: "lopezdp",
			outerWidth: "",
			innerHeight: "",
			outerHeight: "",
			navBarHeight: "",
			imageHeight1: "",
			imageHeight2: "",
			imageHeight3: "",
			modalShow: true,
			renewalStep: 0,
			isTouched: false,
		};
	}

	componentDidMount() {
		const outerWidth = window.innerWidth; //

		this.setState({
			outerWidth: outerWidth.toString(),
		});
	}

	/*.function takes bool from AVRS Modal to display accordingly */
	setModalShow = (show) => {
		this.setState({
			modalShow: show,
		});
	};

	/* function takes step from AVRS child component */
	setRenewalStep = (renewalStep) => {
		this.setState({
			renewalStep: renewalStep,
		});
	};

	handleTouch = (e) => {
		this.setState({
			isTouched: true,
		});
	};

	handleImageLoad1 = (event) => {
		// Do whatever you want here
		const innerHeight = window.innerHeight; //event.target.clientHeight;
		const outerHeight = window.outerHeight; //
		const bgImgHeight1 = event.target.clientHeight;

		this.setState({
			innerHeight: innerHeight.toString(),
			outerHeight: outerHeight.toString(),
			imageHeight1: bgImgHeight1.toString(),
		});
	};

	handleImageLoad2 = (event) => {
		// Do whatever you want here
		const innerHeight = window.innerHeight; //event.target.clientHeight;
		const outerHeight = window.outerHeight; //
		const bgImgHeight2 = event.target.clientHeight;

		this.setState({
			innerHeight: innerHeight.toString(),
			outerHeight: outerHeight.toString(),
			imageHeight2: bgImgHeight2.toString(),
		});
	};

	handleImageLoad3 = (event) => {
		// Do whatever you want here
		const innerHeight = window.innerHeight; //event.target.clientHeight;
		const outerHeight = window.outerHeight; //
		const bgImgHeight3 = event.target.clientHeight;

		this.setState({
			innerHeight: innerHeight.toString(),
			outerHeight: outerHeight.toString(),
			imageHeight3: bgImgHeight3.toString(),
		});
	};

	handleNavLoad = (event) => {
		// Do whatever you want here
		const navBarHeight = event.target.clientHeight;

		this.setState({
			navBarHeight: navBarHeight.toString(),
		});
	};

	render() {
		const renewalProps = {
			setRenewalStep: this.setRenewalStep,
		};
		/*

			These details get to handle styles for header and AvrsForm framing

		 */
		console.log("NAV BAR HEIGHT: ", this.state.navBarHeight);
		console.log("INNER HEIGHT: ", this.state.innerHeight);
		console.log("IMAGE BG HEIGHT1: ", this.state.imageHeight1);
		console.log("IMAGE BG HEIGHT2: ", this.state.imageHeight2);
		console.log("IMAGE BG HEIGHT3: ", this.state.imageHeight3);

		const ssCenterLine = parseInt(this.state.imageHeight1);
		const porscheCenterLine = parseInt(this.state.imageHeight3);

		let hdrSpacing = "";
		let iconSpacing = "";
		let buttonSpacing = "";
		let valuePropSpacing = "";
		let h2FontSz = "";
		let h2Top = "";
		let ulFontSz = "";
		let ulTop = "";
		let valueProp2Offset = "";

		let dmvLineTopMargin = "";

		let porscheSpacing = "";

		let rowHeight = "";

		let googleMapHeight = "";

		let ftrRowHeight = "";

		// this is centering the elements in parallax based off screen height
		if (parseInt(this.state.innerHeight) > 1000) {
			console.log("SCREEN HEIGHT > 1000: ", this.state.innerHeight);
			hdrSpacing = (ssCenterLine / 18).toString();
			iconSpacing = (ssCenterLine / 27).toString();
			buttonSpacing = (ssCenterLine / 39).toString();

			valuePropSpacing = (ssCenterLine / 9).toString();

			porscheSpacing = (porscheCenterLine / 2).toString();

			googleMapHeight = "93vh";
			// Spacing elements on porsche parallax using img centerline
			if (parseInt(this.state.outerWidth) >= 1900) {
				porscheSpacing = (porscheCenterLine / 18).toString();
			} else if (
				parseInt(this.state.outerWidth) >= 1600 &&
				parseInt(this.state.outerWidth) < 1900
			) {
				porscheSpacing = (porscheCenterLine / 9).toString();
			} else if (
				parseInt(this.state.outerWidth) >= 1300 &&
				parseInt(this.state.outerWidth) < 1600
			) {
				porscheSpacing = (porscheCenterLine / 9).toString(); // NOTE: Here is ipad pro screen size
			} else if (
				parseInt(this.state.outerWidth) >= 1110 &&
				parseInt(this.state.outerWidth) < 1300
			) {
				porscheSpacing = (porscheCenterLine / 18).toString();
			} else if (
				parseInt(this.state.outerWidth) >= 1000 &&
				parseInt(this.state.outerWidth) < 1110
			) {
				porscheSpacing = (porscheCenterLine / 42).toString();
			} else if (
				parseInt(this.state.outerWidth) >= 900 &&
				parseInt(this.state.outerWidth) < 1000
			) {
				porscheSpacing = (porscheCenterLine / 50).toString();
			}

			// this gets the header avrs row height
			if (parseInt(this.state.outerWidth) >= 1366) {
				if (this.state.renewalStep.toString() === "1") {
					rowHeight = "75"; // this is complete

					ftrRowHeight = "55"; // this is complete
				} else if (this.state.renewalStep.toString() === "2") {
					rowHeight = "80"; // this is complete

					ftrRowHeight = "60"; // this is complete
				} else if (this.state.renewalStep.toString() === "3") {
					rowHeight = "75"; // this is complete

					ftrRowHeight = "55"; // this is complete
				} else if (this.state.renewalStep.toString() === "4") {
					rowHeight = "85";

					ftrRowHeight = "60"; // this is complete
				} else if (this.state.renewalStep.toString() === "5") {
					rowHeight = "60";

					ftrRowHeight = "60"; // this is complete
				} else if (this.state.renewalStep.toString() === "6") {
					rowHeight = "60";

					ftrRowHeight = "60"; // this is complete
				}
			} else if (
				parseInt(this.state.outerWidth) < 1366 &&
				parseInt(this.state.outerWidth) >= 900
			) {
				if (this.state.renewalStep.toString() === "1") {
					rowHeight = "90";

					ftrRowHeight = "95";
				} else if (this.state.renewalStep.toString() === "2") {
					rowHeight = "85";

					ftrRowHeight = "95";
				} else if (this.state.renewalStep.toString() === "3") {
					rowHeight = "85";

					ftrRowHeight = "95";
				} else if (this.state.renewalStep.toString() === "4") {
					rowHeight = "85";

					ftrRowHeight = "95";
				} else if (this.state.renewalStep.toString() === "5") {
					rowHeight = "85";

					ftrRowHeight = "95";
				} else if (this.state.renewalStep.toString() === "6") {
					rowHeight = "85";

					ftrRowHeight = "95";
				}
			}
		} else if (
			parseInt(this.state.innerHeight) <= 1000 &&
			parseInt(this.state.innerHeight) >= 720 // weird shit at 720
		) {
			// FIXME: Need to adjust this to deal with small landscape
			console.log("SCREEN HEIGHT < 1000?: ", this.state.innerHeight);
			hdrSpacing = (ssCenterLine / 18).toString();
			iconSpacing = (ssCenterLine / 27).toString();
			buttonSpacing = (ssCenterLine / 33).toString();

			valuePropSpacing = (ssCenterLine / 6).toString();

			// Spacing elements on porsche parallax using img centerline
			if (parseInt(this.state.outerWidth) >= 1900) {
				porscheSpacing = (porscheCenterLine / 18).toString();
				googleMapHeight = "81vh";
			} else if (
				parseInt(this.state.outerWidth) >= 1600 &&
				parseInt(this.state.outerWidth) < 1900
			) {
				porscheSpacing = (porscheCenterLine / 9).toString();
				googleMapHeight = "81vh";
			} else if (
				parseInt(this.state.outerWidth) >= 1300 &&
				parseInt(this.state.outerWidth) < 1600
			) {
				porscheSpacing = (porscheCenterLine / 27).toString(); // NOTE: Here is ipad pro screen size
				googleMapHeight = "81vh";
			} else if (
				parseInt(this.state.outerWidth) >= 1110 &&
				parseInt(this.state.outerWidth) < 1300
			) {
				porscheSpacing = (porscheCenterLine / 33).toString();
				googleMapHeight = "81vh";
			} else if (
				parseInt(this.state.outerWidth) >= 1000 &&
				parseInt(this.state.outerWidth) < 1110
			) {
				porscheSpacing = (porscheCenterLine / 48).toString();
				googleMapHeight = "81vh";
			} else if (
				parseInt(this.state.outerWidth) >= 900 &&
				parseInt(this.state.outerWidth) < 1000
			) {
				porscheSpacing = (porscheCenterLine / 60).toString();
				googleMapHeight = "81vh";
			} else if (
				parseInt(this.state.outerWidth) >= 600 &&
				parseInt(this.state.outerWidth) < 900
			) {
				porscheSpacing = (porscheCenterLine / 60).toString();
				googleMapHeight = "81vh";
			} else if (parseInt(this.state.outerWidth) < 600) {
				porscheSpacing = (porscheCenterLine / 60).toString();
				googleMapHeight = "95vh";
			}

			// this gets the header avrs row height
			if (parseInt(this.state.outerWidth) >= 1366) {
				if (this.state.renewalStep.toString() === "1") {
					rowHeight = "95"; // this is complete

					ftrRowHeight = "72"; // this is complete
				} else if (this.state.renewalStep.toString() === "2") {
					rowHeight = "105"; // this is complete

					ftrRowHeight = "80"; // this is complete
				} else if (this.state.renewalStep.toString() === "3") {
					rowHeight = "95"; // this is complete

					ftrRowHeight = "70"; // this is complete
				} else if (this.state.renewalStep.toString() === "4") {
					rowHeight = "95"; // this is complete

					ftrRowHeight = "65"; // this is complete
				} else if (this.state.renewalStep.toString() === "5") {
					rowHeight = "90"; // this is complete

					ftrRowHeight = "70"; // this is complete
				} else if (this.state.renewalStep.toString() === "6") {
					rowHeight = "90"; // this is complete

					ftrRowHeight = "70"; // this is complete
				}
			} else if (
				parseInt(this.state.outerWidth) < 1366 &&
				parseInt(this.state.outerWidth) >= 900
			) {
				if (this.state.renewalStep.toString() === "1") {
					rowHeight = "95";

					ftrRowHeight = "72";
				} else if (this.state.renewalStep.toString() === "2") {
					rowHeight = "105";

					ftrRowHeight = "80";
				} else if (this.state.renewalStep.toString() === "3") {
					rowHeight = "95";

					ftrRowHeight = "70";
				} else if (this.state.renewalStep.toString() === "4") {
					rowHeight = "95";

					ftrRowHeight = "65";
				} else if (this.state.renewalStep.toString() === "5") {
					rowHeight = "90";

					ftrRowHeight = "70";
				} else if (this.state.renewalStep.toString() === "6") {
					rowHeight = "90";

					ftrRowHeight = "70";
				}
			} else if (
				// FIXME: NEED < 900 ~ >= 500 MAYBE ANDROID LANDSCAPES!!!
				parseInt(this.state.outerWidth) < 900 &&
				parseInt(this.state.outerWidth) >= 380 &&
				parseInt(this.state.innerHeight) > 320
			) {
				if (this.state.renewalStep.toString() === "1") {
					rowHeight = "90"; // done NOTE: Perfect for 319px iOS need precision

					ftrRowHeight = "80"; // done
				} else if (this.state.renewalStep.toString() === "2") {
					rowHeight = "108"; // done

					ftrRowHeight = "81"; // done
				} else if (this.state.renewalStep.toString() === "3") {
					rowHeight = "99"; // done

					ftrRowHeight = "78"; // done
				} else if (this.state.renewalStep.toString() === "4") {
					rowHeight = "95";

					ftrRowHeight = "75"; // done
				} else if (this.state.renewalStep.toString() === "5") {
					rowHeight = "90";

					ftrRowHeight = "70";
				} else if (this.state.renewalStep.toString() === "6") {
					rowHeight = "90";

					ftrRowHeight = "70";
				}
			}
		} else if (
			parseInt(this.state.innerHeight) < 720 // weird shit at 720
		) {
			// FIXME: shit
			// NOTE: This iPad Mini!!!
			// TODO: smaller landscapes and portraits on mobile
			// FIXME: Need to adjust this to deal with small landscape
			console.log("SCREEN HEIGHT < 1000: ", this.state.innerHeight);
			hdrSpacing = (ssCenterLine / 18).toString();
			iconSpacing = (ssCenterLine / 27).toString();
			buttonSpacing = (ssCenterLine / 33).toString();

			valuePropSpacing = (ssCenterLine / 6).toString();

			// Spacing elements on porsche parallax using img centerline
			if (parseInt(this.state.outerWidth) >= 1900) {
				porscheSpacing = (porscheCenterLine / 18).toString();
				googleMapHeight = "72vh";
			} else if (
				parseInt(this.state.outerWidth) >= 1600 &&
				parseInt(this.state.outerWidth) < 1900
			) {
				porscheSpacing = (porscheCenterLine / 9).toString();
				googleMapHeight = "72vh";
			} else if (
				parseInt(this.state.outerWidth) >= 1300 &&
				parseInt(this.state.outerWidth) < 1600
			) {
				porscheSpacing = (porscheCenterLine / 27).toString(); // NOTE: Here is ipad pro screen size
				googleMapHeight = "72vh";
			} else if (
				parseInt(this.state.outerWidth) >= 1110 &&
				parseInt(this.state.outerWidth) < 1300
			) {
				porscheSpacing = (porscheCenterLine / 33).toString();
				googleMapHeight = "72vh";
			} else if (
				parseInt(this.state.outerWidth) >= 1000 &&
				parseInt(this.state.outerWidth) < 1110
			) {
				// NOTE: DO NOT TAKE THIS VIEW TO ANY LESS THAN 667px HEIGHT
				porscheSpacing = (porscheCenterLine / 21).toString();
				googleMapHeight = "72vh";
				console.log("MINIIIIIIIII");
			} else if (
				parseInt(this.state.outerWidth) >= 900 &&
				parseInt(this.state.outerWidth) < 1000 //here
			) {
				porscheSpacing = (porscheCenterLine / 60).toString();
				googleMapHeight = "72vh";
			} else if (
				parseInt(this.state.outerWidth) >= 600 &&
				parseInt(this.state.outerWidth) < 900
			) {
				porscheSpacing = (porscheCenterLine / 60).toString();
				googleMapHeight = "51vh";
			} else if (parseInt(this.state.outerWidth) < 600) {
				porscheSpacing = (porscheCenterLine / 60).toString();
				googleMapHeight = "95vh";
			}

			// this gets the header avrs row height
			if (parseInt(this.state.outerWidth) >= 1366) {
				if (this.state.renewalStep.toString() === "1") {
					rowHeight = "95"; // this is complete

					ftrRowHeight = "72"; // this is complete
				} else if (this.state.renewalStep.toString() === "2") {
					rowHeight = "105"; // this is complete

					ftrRowHeight = "80"; // this is complete
				} else if (this.state.renewalStep.toString() === "3") {
					rowHeight = "95"; // this is complete

					ftrRowHeight = "70"; // this is complete
				} else if (this.state.renewalStep.toString() === "4") {
					rowHeight = "95"; // this is complete

					ftrRowHeight = "65"; // this is complete
				} else if (this.state.renewalStep.toString() === "5") {
					rowHeight = "90"; // this is complete

					ftrRowHeight = "70"; // this is complete
				} else if (this.state.renewalStep.toString() === "6") {
					rowHeight = "90"; // this is complete

					ftrRowHeight = "70"; // this is complete
				}
			} else if (
				parseInt(this.state.outerWidth) < 1366 &&
				parseInt(this.state.outerWidth) >= 1000
			) {
				if (this.state.renewalStep.toString() === "1") {
					rowHeight = "105"; // done

					if (
						parseInt(this.state.outerWidth) >= 800 &&
						parseInt(this.state.outerWidth) < 900
					) {
						ftrRowHeight = "56";
					} else {
						ftrRowHeight = "80";
					}
				} else if (this.state.renewalStep.toString() === "2") {
					rowHeight = "117"; // done

					ftrRowHeight = "81";
				} else if (this.state.renewalStep.toString() === "3") {
					rowHeight = "108";

					ftrRowHeight = "78";
				} else if (this.state.renewalStep.toString() === "4") {
					rowHeight = "95";

					ftrRowHeight = "75";
				} else if (this.state.renewalStep.toString() === "5") {
					rowHeight = "90";

					ftrRowHeight = "70";
				} else if (this.state.renewalStep.toString() === "6") {
					rowHeight = "90";

					ftrRowHeight = "70";
				}
			} else if (
				parseInt(this.state.outerWidth) < 1000 &&
				parseInt(this.state.outerWidth) >= 800
			) {
				if (this.state.renewalStep.toString() === "1") {
					rowHeight = "105"; // done

					if (
						parseInt(this.state.outerWidth) >= 800 &&
						parseInt(this.state.outerWidth) < 900
					) {
						ftrRowHeight = "56";
					} else {
						ftrRowHeight = "80";
					}
				} else if (this.state.renewalStep.toString() === "2") {
					rowHeight = "117"; // done

					ftrRowHeight = "81";
				} else if (this.state.renewalStep.toString() === "3") {
					rowHeight = "108";

					ftrRowHeight = "78";
				} else if (this.state.renewalStep.toString() === "4") {
					rowHeight = "95";

					ftrRowHeight = "75";
				} else if (this.state.renewalStep.toString() === "5") {
					rowHeight = "90";

					ftrRowHeight = "70";
				} else if (this.state.renewalStep.toString() === "6") {
					rowHeight = "90";

					ftrRowHeight = "70";
				}
			} else if (
				// FIXME: THIS IS NOT BEING USED YET????? MAYBE ANDROID!!!
				parseInt(this.state.outerWidth) < 800 &&
				parseInt(this.state.outerWidth) >= 380 &&
				parseInt(this.state.innerHeight) > 320
			) {
				if (this.state.renewalStep.toString() === "1") {
					rowHeight = "99"; // done NOTE: Perfect for 319px iOS need precision

					ftrRowHeight = "80"; // done
				} else if (this.state.renewalStep.toString() === "2") {
					rowHeight = "108"; // done

					ftrRowHeight = "81"; // done
				} else if (this.state.renewalStep.toString() === "3") {
					rowHeight = "99"; // done

					ftrRowHeight = "78"; // done
				} else if (this.state.renewalStep.toString() === "4") {
					rowHeight = "95";

					ftrRowHeight = "75"; // done
				} else if (this.state.renewalStep.toString() === "5") {
					rowHeight = "90";

					ftrRowHeight = "70";
				} else if (this.state.renewalStep.toString() === "6") {
					rowHeight = "90";

					ftrRowHeight = "70";
				}
			} else if (
				parseInt(this.state.outerWidth) < 380 &&
				parseInt(this.state.outerWidth) >= 350 &&
				parseInt(this.state.innerHeight) > 320
			) {
				if (this.state.renewalStep.toString() === "1") {
					rowHeight = "90"; // done NOTE: Perfect for 319px iOS need precision

					ftrRowHeight = "80"; // done
				} else if (this.state.renewalStep.toString() === "2") {
					rowHeight = "108"; // done

					ftrRowHeight = "81"; // done
				} else if (this.state.renewalStep.toString() === "3") {
					rowHeight = "99"; // done

					ftrRowHeight = "78"; // done
				} else if (this.state.renewalStep.toString() === "4") {
					rowHeight = "95";

					ftrRowHeight = "75"; // done
				} else if (this.state.renewalStep.toString() === "5") {
					rowHeight = "90";

					ftrRowHeight = "70";
				} else if (this.state.renewalStep.toString() === "6") {
					rowHeight = "90";

					ftrRowHeight = "70";
				}
			} else if (
				parseInt(this.state.outerWidth) < 350 &&
				parseInt(this.state.outerWidth) >= 200 &&
				parseInt(this.state.innerHeight) > 320
			) {
				if (this.state.renewalStep.toString() === "1") {
					rowHeight = "105"; // done NOTE: Perfect for 319px iOS need precision

					ftrRowHeight = "80"; // done
				} else if (this.state.renewalStep.toString() === "2") {
					rowHeight = "108"; // done

					ftrRowHeight = "81"; // done
				} else if (this.state.renewalStep.toString() === "3") {
					rowHeight = "99"; // done

					ftrRowHeight = "78"; // done
				} else if (this.state.renewalStep.toString() === "4") {
					rowHeight = "95";

					ftrRowHeight = "75"; // done
				} else if (this.state.renewalStep.toString() === "5") {
					rowHeight = "90";

					ftrRowHeight = "70";
				} else if (this.state.renewalStep.toString() === "6") {
					rowHeight = "90";

					ftrRowHeight = "70";
				}
			}
		}

		// this handling font size and margin top
		if (parseInt(this.state.outerWidth) > 1800) {
			h2FontSz = "40";
			h2Top = "36";

			ulFontSz = "26";
			ulTop = "58";

			dmvLineTopMargin = "200";
		} else if (
			parseInt(this.state.outerWidth) > 1340 &&
			parseInt(this.state.outerWidth) <= 1800
		) {
			h2FontSz = "26";
			h2Top = "30";

			ulFontSz = "22";
			ulTop = "60";

			dmvLineTopMargin = "200";
		} else if (
			parseInt(this.state.outerWidth) > 1015 &&
			parseInt(this.state.outerWidth) <= 1340
		) {
			h2FontSz = "24";
			h2Top = "27";

			ulFontSz = "19";
			ulTop = "63";
		} else if (
			parseInt(this.state.outerWidth) > 899 &&
			parseInt(this.state.outerWidth) <= 1015
		) {
			h2FontSz = "18";
			h2Top = "21";

			ulFontSz = "16";
			ulTop = "60";
		} else if (parseInt(this.state.outerWidth) < 899) {
			h2FontSz = "18";
			h2Top = "21";

			ulFontSz = "16";
			ulTop = "60";
		}

		// this is handling the valuePropOffset
		// but which one?!?!?!? more descriptive vars please!
		if (parseInt(this.state.outerWidth) >= 1600) {
			valueProp2Offset = 1.4;
		} else if (
			parseInt(this.state.outerWidth) < 1600 &&
			parseInt(this.state.outerWidth) >= 1015
		) {
			valueProp2Offset = 1.2;
		} else if (
			parseInt(this.state.outerWidth) < 1015 &&
			parseInt(this.state.outerWidth) >= 900
		) {
			valueProp2Offset = 1.2;
		} else if (
			parseInt(this.state.outerWidth) < 900 &&
			parseInt(this.state.outerWidth) >= 600
		) {
			// FIXME: Need to differentiate by height for this
			valueProp2Offset = 1.3;
		} else if (
			parseInt(this.state.outerWidth) < 600 &&
			parseInt(this.state.outerWidth) >= 380
		) {
			// FIXME: Need to differentiate by height for this
			valueProp2Offset = 1.3;
		} else if (
			parseInt(this.state.outerWidth) < 380 &&
			parseInt(this.state.outerWidth) >= 350
		) {
			// FIXME: Need to differentiate by height for this
			valueProp2Offset = 1.4;
		} else if (
			parseInt(this.state.outerWidth) < 350 &&
			parseInt(this.state.outerWidth) >= 200
		) {
			// FIXME: Need to differentiate by height for this
			valueProp2Offset = 1.8;
		}

		const hdrSpacingStyleObj = {
			marginTop: hdrSpacing + "px",
		};

		const iconRowStyleObj = {
			marginTop: iconSpacing + "px",
		};

		const buttonStyleObj = {
			marginTop: buttonSpacing + "px",
		};

		const valuePropStyleObj = {
			marginTop: valuePropSpacing + "px",
		};

		const h2StyleObject = {
			fontSize: h2FontSz + "px",
			top: h2Top + "%",
		};
		const ulStyleObject = {
			fontSize: ulFontSz + "px",
			top: ulTop + "%",
		};

		const dmvLineStyleObj = {
			marginTop: dmvLineTopMargin + "px",
		};

		// this adds margin-top styling to avrs element on porsche parallax
		const footerStyleObj = {
			marginTop: porscheSpacing + "px",
		};

		const liStyleObject = {};

		const googleMapStyle = {
			width: "100%",
			height: googleMapHeight,
		};

		const avrsRowHdrStyle = {
			height: rowHeight + "%",
		};

		const ftrHeightStyle = {
			height: ftrRowHeight + "%",
		};

		return (
			<div className="view" onTouchStart={this.handleTouch}>
				{/* NOTE: 319px Small Mobile XS-Views!!!*/}
				<Media
					query="(max-width: 568px)"
					render={() => (
						<Container>
							<Container className="app-view-lander">
								<Container className="main-view-lander">
									<Parallax
										pages={3.6}
										scrolling={true}
										vertical
										ref={(ref) => (this.parallax = ref)}
										className="p-outer"
									>
										{/* Covers the ss bg img */}
										<ParallaxLayer
											offset={0.06}
											speed={0.1}
											factor={6}
											style={
												{
													/*
												backgroundImage: url(
													"qat.dmv.camaro.jpg",
													"content",
													true
												),
												backgroundSize: "140%",
												*/
												}
											}
											className="ss-img"
											id="ss-root"
										>
											<article>
												<Image
													src="https://cdn-assets.quickautotags.com/images/content/qat.dmv.camaro.jpg"
													className="ss-bg-img-sm"
													onLoad={
														this.handleImageLoad1
													}
												/>
											</article>
										</ParallaxLayer>
										{/* Covers the lines bg img */}
										<ParallaxLayer
											offset={1}
											speed={0.5}
											factor={4}
											style={
												{
													/*
												backgroundImage: url(
													"qat.dmv.camaro.jpg",
													"content",
													true
												),
												backgroundSize: "140%",
												*/
												}
											}
											className="lines-img"
											id="ss-root"
										>
											<article>
												<Image
													src="https://cdn-assets.quickautotags.com/images/content/ca-dmv-long-lines.jpg"
													className="lines-bg-img-sm"
													onLoad={
														this.handleImageLoad2
													}
												/>
											</article>
										</ParallaxLayer>
										{/* Covers the porsche bg img */}
										<ParallaxLayer
											offset={2}
											speed={0.5}
											factor={4}
											style={
												{
													/*
												backgroundImage: url(
													"qat.dmv.camaro.jpg",
													"content",
													true
												),
												backgroundSize: "140%",
												*/
												}
											}
											className="lines-img"
											id="ss-root"
										>
											<article>
												<section className="porsche-img">
													<Image
														src="https://cdn-assets.quickautotags.com/images/content/ca-dmv-registration-renewals.jpg"
														className="porsche-bg-img-sm"
														onLoad={
															this
																.handleImageLoad3
														}
													/>
												</section>
											</article>
										</ParallaxLayer>

										{/* Covers the header */}
										{/* Value Prop with Nav & AVRS */}
										<ParallaxLayer
											offset={0} // NOTE: this handles the margin above NAV
											speed={1}
											style={
												{
													//backgroundSize: "100%",
												}
											}
											className="value-prop"
										>
											<Navbar
												className="app-navi-bar-xs test"
												bg="light"
												variant="light"
												onLoad={this.handleNavLoad}
											>
												<Row className="brand-lg container">
													<Col className="profile-img-col-xs">
														<Navbar.Brand
															as={NavLink}
															to="/"
															className="logo-element"
														>
															<Image
																src="https://cdn-assets.quickautotags.com/images/branding/Logo.QAT.png"
																className="App-logo-xs"
															/>
														</Navbar.Brand>
													</Col>
												</Row>

												<Row className="brand-lg container">
													<Col
														sm={3}
														className="contact-num-sm contact-num-style-sm"
													>
														<FaPhoneAlt className="phone-icon" />
														<PhoneNumber
															number=" +18887230842"
															isLinked={true}
														/>
													</Col>
												</Row>
											</Navbar>

											<article className="header-cta-sm">
												<Col>
													<h1
														style={
															hdrSpacingStyleObj
														}
													>
														Get your vehicle
														registered today
													</h1>
												</Col>
											</article>

											<article className="header-cta-icons">
												<Row
													className="icon-row"
													style={iconRowStyleObj}
												>
													<Col>
														<h3 className="header-cta-titles-sm">
															Skip the DMV
															headache
														</h3>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/avoid-the-line-ca-dmv.png"
															className="App-logo-md"
														/>
													</Col>
													<Col>
														<h3 className="header-cta-titles-sm">
															Register online or
															in person
														</h3>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/dmv-register-online-icon.png"
															className="App-logo-md"
														/>
													</Col>
													<Col>
														<h3 className="header-cta-titles-sm">
															Fast and
															professional
														</h3>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/skip-the-line-rocket-dmv.png"
															className="App-logo-md"
														/>
													</Col>
												</Row>
												<Row className="btn-row">
													<Col
														xs={9}
														className="btn-column"
													>
														<UiLoadBtn
															block
															size="md"
															className="modal-btn-md"
															style={
																buttonStyleObj
															}
															disabled={
																this.state
																	.isLoading
															}
															//variant="danger"
															type="button"
															onClick={this.setModalShow.bind(
																this,
																true
															)}
															isLoading={
																this.state
																	.isLoading
															}
															text="Renew Today"
															loadingText="Creating..."
														/>
													</Col>
												</Row>
											</article>

											<article
												className="header-cta-value-avrs-sm"
												style={valuePropStyleObj}
											>
												<Row
													className="header-avrs-row-sm"
													style={avrsRowHdrStyle}
												>
													{/*
													<Col className="header-avrs-col-left-md">
														<h2
															style={
																h2StyleObject
															}
														>
															If You need any of
															the following we can
															help you save time
															and money
														</h2>

														<section>
															<ul
																style={
																	ulStyleObject
																}
															>
																<li
																	style={
																		liStyleObject
																	}
																>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Skip the DMV
																	lines & wait
																</li>
																<li>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Instantly
																	renew your
																	tags by
																	phone, text,
																	or email
																</li>
																<li>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Get a free
																	e-copy of
																	your
																	registration
																</li>
																<li>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Free
																	delivery of
																	your sticker
																	&
																	registration
																</li>
															</ul>
														</section>
													</Col>
												*/}
													<Col className="header-avrs-col-mobile-sm">
														<article>
															{/*
															<header className="avrs-col-hdr-md">
																Get Started Here
															</header>
															*/}
															<section className="avrs-container-mobile">
																<AvrsFormPrimary
																	view="avrs-hdr"
																	funcProps={
																		renewalProps
																	}
																	className="avrs-widget-sm"
																/>
															</section>
														</article>
													</Col>
												</Row>
											</article>

											<Col
												lg={11}
												className="lines-col-sm"
											>
												<article
													className="avoid-the-dmv-line"
													style={dmvLineStyleObj}
												>
													<h2>
														On average a trip to the
														DMV can take 45-130
														minutes
													</h2>
												</article>
											</Col>
										</ParallaxLayer>

										{/* Covers the 2ndary value prop under lines */}
										<ParallaxLayer
											offset={valueProp2Offset} // FIXME: This controls the 2ndary valueprop
											speed={1}
											factor={1.5}
											style={{
												backgroundColor: "white",
												opacity: 1,
											}}
										>
											<Row>
												<Col
													lg={12}
													className="vp2-col"
												>
													<article className="vp2-sm">
														<h2>
															Our Agents will help
															you get registered
															to get you on your
															way today
														</h2>
													</article>
												</Col>
											</Row>

											<article className="header-cta-icons">
												<Row className="btn-row">
													<Col
														xs={9}
														className="btn-column"
													>
														<UiLoadBtn
															block
															size="sm"
															className="modal-btn"
															style={
																buttonStyleObj
															}
															disabled={
																this.state
																	.isLoading
															}
															//variant="danger"
															type="button"
															onClick={this.setModalShow.bind(
																this,
																true
															)}
															isLoading={
																this.state
																	.isLoading
															}
															text="Renew Today"
															loadingText="Creating..."
														/>
													</Col>
												</Row>
												<hr />
												<Row className="vp2-row">
													<Col
														lg={9}
														className="vp2-col"
													>
														<article className="vp2-sm">
															<h2>
																Where we operate
															</h2>
															<h3>
																Register your
																vehicle
															</h3>
														</article>
													</Col>
												</Row>
												<Row
													className="vp2-row"
													style={iconRowStyleObj}
												>
													<Col>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/ca-dmv-online-icon.png"
															className="vp2-logo-sm"
														/>
														<h3 className="vp2-cta-titles-sm">
															Online
														</h3>
													</Col>
													<Col>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/dmv-meeting.png"
															className="vp2-logo-sm"
														/>
														<h3 className="vp2-cta-titles-sm">
															In person
														</h3>
													</Col>
													<Col>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/phone-email-icon.png"
															className="vp2-logo-sm"
														/>
														<h3 className="vp2-cta-titles-sm">
															Phone or Email
														</h3>
													</Col>
												</Row>
											</article>
											<Row>
												<div style={googleMapStyle}>
													<GoogleMap />
												</div>
											</Row>

											<article
												className="ftr-cta-value-avrs"
												style={footerStyleObj}
											>
												<Row
													className="ftr-avrs-row"
													style={ftrHeightStyle}
												>
													<Col className="ftr-avrs-col-right-sm porsche-right">
														<section>
															<AvrsFormSecondary
																view="porsche"
																funcProps={
																	renewalProps
																}
															/>
														</section>
													</Col>
												</Row>
												<Row
													className="ftr-avrs-row"
													style={ftrHeightStyle}
												>
													<Col className="ftr-avrs-col-left-md">
														<h2
															className="ftr-hdr"
															style={
																h2StyleObject
															}
														>
															Contact Us
														</h2>

														<section>
															<ul
																style={
																	ulStyleObject
																}
															>
																<li
																	style={
																		liStyleObject
																	}
																>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Location
																</li>

																<li
																	style={{
																		paddingLeft:
																			"1.75em",
																	}}
																>
																	<a href="https://www.google.com/maps/place/5586+Mission+Boulevard,+Riverside,+CA+92509/@33.996608,-117.4090365,17z">
																		5586
																		Mission
																		Blvd
																		Riverside,
																		CA 92509
																	</a>
																</li>

																<li>
																	<FaRegCheckSquare className="checkbox-icon" />
																	<PhoneNumber
																		number=" +18887230842"
																		isLinked={
																			true
																		}
																		style={{
																			color:
																				"white",
																		}}
																	/>
																</li>
																<li>
																	<Link
																		to="/privacy-policy"
																		className="ftr-block"
																		onClick={
																			void 0
																		}
																		onTouchStart={() =>
																			""
																		}
																	>
																		<FaRegCheckSquare className="checkbox-icon" />
																		Privacy
																		Policy
																	</Link>
																</li>
																<li>
																	<Link
																		to="/terms-conditions"
																		className="ftr-block"
																		onClick={
																			void 0
																		}
																		onTouchStart={() =>
																			""
																		}
																	>
																		<FaRegCheckSquare className="checkbox-icon" />
																		Terms &
																		Conditions
																	</Link>
																</li>
															</ul>
														</section>
													</Col>
												</Row>
											</article>
										</ParallaxLayer>

										{/* Need the Footer Menu to display new features */}
										<Footer />

										<AvrsModal
											show={this.state.modalShow}
											setModalShow={this.setModalShow}
											isAuthenticated={
												this.props.isAuthenticated
											}
											userHasAuthenticated={
												this.props.userHasAuthenticated
											}
										/>
									</Parallax>
								</Container>
							</Container>
						</Container>
					)}
				/>
				{/* NOTE: 568px medium landscape md-Views!!!*/}
				<Media
					query="(min-width: 568px) and (max-width: 899px)"
					render={() => (
						<Container>
							<Container className="app-view-lander">
								<Container className="main-view-lander">
									<Parallax
										pages={3.011}
										scrolling={true}
										vertical
										ref={(ref) => (this.parallax = ref)}
										className="p-outer"
									>
										{/* Covers the ss bg img */}
										<ParallaxLayer
											offset={0.06}
											speed={0.1}
											factor={6}
											style={
												{
													/*
												backgroundImage: url(
													"qat.dmv.camaro.jpg",
													"content",
													true
												),
												backgroundSize: "140%",
												*/
												}
											}
											className="ss-img"
											id="ss-root"
										>
											<article>
												<Image
													src="https://cdn-assets.quickautotags.com/images/content/qat.dmv.camaro.jpg"
													className="ss-bg-img-md"
													onLoad={
														this.handleImageLoad1
													}
												/>
											</article>
										</ParallaxLayer>
										{/* Covers the lines bg img */}
										<ParallaxLayer
											offset={1}
											speed={0.5}
											factor={4}
											style={
												{
													/*
												backgroundImage: url(
													"qat.dmv.camaro.jpg",
													"content",
													true
												),
												backgroundSize: "140%",
												*/
												}
											}
											className="lines-img"
											id="ss-root"
										>
											<article>
												<Image
													src="https://cdn-assets.quickautotags.com/images/content/ca-dmv-long-lines.jpg"
													className="lines-bg-img"
													onLoad={
														this.handleImageLoad2
													}
												/>
											</article>
										</ParallaxLayer>
										{/* Covers the porsche bg img */}
										<ParallaxLayer
											offset={2}
											speed={0.5}
											factor={4}
											style={
												{
													/*
												backgroundImage: url(
													"qat.dmv.camaro.jpg",
													"content",
													true
												),
												backgroundSize: "140%",
												*/
												}
											}
											className="lines-img"
											id="ss-root"
										>
											<article>
												<section className="porsche-img">
													<Image
														src="https://cdn-assets.quickautotags.com/images/content/ca-dmv-registration-renewals.jpg"
														className="porsche-bg-img"
														onLoad={
															this
																.handleImageLoad3
														}
													/>
												</section>
											</article>
										</ParallaxLayer>

										{/* Covers the header */}
										{/* Value Prop with Nav & AVRS */}
										<ParallaxLayer
											offset={0} // NOTE: this handles the margin above NAV
											speed={1}
											style={
												{
													//backgroundSize: "100%",
												}
											}
											className="value-prop"
										>
											<Navbar
												className="app-navi-bar-sm test"
												bg="light"
												variant="light"
												onLoad={this.handleNavLoad}
											>
												<Row className="brand-lg container">
													<Col className="profile-img-col-lg">
														<Navbar.Brand
															as={NavLink}
															to="/"
															className="logo-element"
														>
															<Image
																src="https://cdn-assets.quickautotags.com/images/branding/Logo.QAT.png"
																className="App-logo-lg"
															/>
														</Navbar.Brand>
													</Col>
													<Col
														md={3}
														className="contact-num contact-num-style"
													>
														<FaPhoneAlt className="phone-icon" />
														<PhoneNumber
															number=" +18887230842"
															isLinked={true}
														/>
													</Col>
												</Row>
											</Navbar>

											<article className="header-cta-md">
												<Col>
													<h1
														style={
															hdrSpacingStyleObj
														}
													>
														Get your vehicle
														registered today
													</h1>
												</Col>
											</article>

											<article className="header-cta-icons">
												<Row
													className="icon-row"
													style={iconRowStyleObj}
												>
													<Col>
														<h3 className="header-cta-titles">
															Skip the DMV
															headache
														</h3>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/avoid-the-line-ca-dmv.png"
															className="App-logo-md"
														/>
													</Col>
													<Col>
														<h3 className="header-cta-titles">
															Register online or
															in person
														</h3>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/dmv-register-online-icon.png"
															className="App-logo-md"
														/>
													</Col>
													<Col>
														<h3 className="header-cta-titles">
															Fast and
															professional
														</h3>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/skip-the-line-rocket-dmv.png"
															className="App-logo-md"
														/>
													</Col>
												</Row>
												<Row className="btn-row">
													<Col
														md={3}
														className="btn-column"
													>
														<UiLoadBtn
															block
															size="md"
															className="modal-btn-md"
															style={
																buttonStyleObj
															}
															disabled={
																this.state
																	.isLoading
															}
															//variant="danger"
															type="button"
															onClick={this.setModalShow.bind(
																this,
																true
															)}
															isLoading={
																this.state
																	.isLoading
															}
															text="Renew Today"
															loadingText="Creating..."
														/>
													</Col>
												</Row>
											</article>

											<article
												className="header-cta-value-avrs-sm"
												style={valuePropStyleObj}
											>
												<Row
													className="header-avrs-row"
													style={avrsRowHdrStyle}
												>
													{/*
													<Col className="header-avrs-col-left-md">
														<h2
															style={
																h2StyleObject
															}
														>
															If You need any of
															the following we can
															help you save time
															and money
														</h2>

														<section>
															<ul
																style={
																	ulStyleObject
																}
															>
																<li
																	style={
																		liStyleObject
																	}
																>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Skip the DMV
																	lines & wait
																</li>
																<li>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Instantly
																	renew your
																	tags by
																	phone, text,
																	or email
																</li>
																<li>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Get a free
																	e-copy of
																	your
																	registration
																</li>
																<li>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Free
																	delivery of
																	your sticker
																	&
																	registration
																</li>
															</ul>
														</section>
													</Col>
												*/}
													<Col className="header-avrs-col-mobile">
														<article>
															<header className="avrs-col-hdr-md">
																Get Started Here
															</header>
															<section>
																<AvrsFormPrimary
																	view="avrs-hdr"
																	funcProps={
																		renewalProps
																	}
																/>
															</section>
														</article>
													</Col>
												</Row>
											</article>

											<Col
												lg={11}
												className="lines-col-sm"
											>
												<article
													className="avoid-the-dmv-line"
													style={dmvLineStyleObj}
												>
													<h2>
														On average a trip to the
														DMV can take 45-130
														minutes
													</h2>
												</article>
											</Col>
										</ParallaxLayer>

										{/* Covers the 2ndary value prop under lines */}
										<ParallaxLayer
											offset={valueProp2Offset} // FIXME: This controls the 2ndary valueprop
											speed={1}
											factor={1.5}
											style={{
												backgroundColor: "white",
												opacity: 1,
											}}
										>
											<Row>
												<Col
													lg={12}
													className="vp2-col"
												>
													<article className="vp2">
														<h2>
															Our Professional
															Agents will help you
															get registered and
															get you on your way
															today
														</h2>
													</article>
												</Col>
											</Row>

											<article className="header-cta-icons">
												<Row className="btn-row">
													<Col
														md={4}
														className="btn-column"
													>
														<UiLoadBtn
															block
															size="lg"
															className="modal-btn"
															style={
																buttonStyleObj
															}
															disabled={
																this.state
																	.isLoading
															}
															//variant="danger"
															type="button"
															onClick={this.setModalShow.bind(
																this,
																true
															)}
															isLoading={
																this.state
																	.isLoading
															}
															text="Renew Today"
															loadingText="Creating..."
														/>
													</Col>
												</Row>
												<hr />
												<Row className="vp2-row">
													<Col
														lg={9}
														className="vp2-col"
													>
														<article className="vp2">
															<h2>
																Where we operate
															</h2>
															<h3>
																Register your
																vehicle
															</h3>
														</article>
													</Col>
												</Row>
												<Row
													className="vp2-row"
													style={iconRowStyleObj}
												>
													<Col>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/ca-dmv-online-icon.png"
															className="vp2-logo-lg"
														/>
														<h3 className="vp2-cta-titles">
															Online
														</h3>
													</Col>
													<Col>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/dmv-meeting.png"
															className="vp2-logo-lg"
														/>
														<h3 className="vp2-cta-titles">
															In person
														</h3>
													</Col>
													<Col>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/phone-email-icon.png"
															className="vp2-logo-lg"
														/>
														<h3 className="vp2-cta-titles">
															Phone or Email
														</h3>
													</Col>
												</Row>
											</article>
											<Row>
												<div style={googleMapStyle}>
													<GoogleMap />
												</div>
											</Row>

											<article
												className="ftr-cta-value-avrs"
												style={footerStyleObj}
											>
												<Row
													className="ftr-avrs-row"
													style={ftrHeightStyle}
												>
													<Col className="ftr-avrs-col-left-md">
														<h2
															className="ftr-hdr"
															style={
																h2StyleObject
															}
														>
															Contact Us
														</h2>

														<section>
															<ul
																style={
																	ulStyleObject
																}
															>
																<li
																	style={
																		liStyleObject
																	}
																>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Location
																</li>

																<li
																	style={{
																		paddingLeft:
																			"1.75em",
																	}}
																>
																	<a href="https://www.google.com/maps/place/5586+Mission+Boulevard,+Riverside,+CA+92509/@33.996608,-117.4090365,17z">
																		5586
																		Mission
																		Blvd
																		Riverside,
																		CA 92509
																	</a>
																</li>

																<li>
																	<FaRegCheckSquare className="checkbox-icon" />
																	<PhoneNumber
																		number=" +18887230842"
																		isLinked={
																			true
																		}
																		style={{
																			color:
																				"white",
																		}}
																	/>
																</li>
																<li>
																	<Link
																		to="/privacy-policy"
																		className="ftr-block"
																		onClick={
																			void 0
																		}
																		onTouchStart={() =>
																			""
																		}
																	>
																		<FaRegCheckSquare className="checkbox-icon" />
																		Privacy
																		Policy
																	</Link>
																</li>
																<li>
																	<Link
																		to="/terms-conditions"
																		className="ftr-block"
																		onClick={
																			void 0
																		}
																		onTouchStart={() =>
																			""
																		}
																	>
																		<FaRegCheckSquare className="checkbox-icon" />
																		Terms &
																		Conditions
																	</Link>
																</li>
															</ul>
														</section>
													</Col>
													<Col className="ftr-avrs-col-right-md porsche-right">
														<section>
															<AvrsFormSecondary
																view="porsche"
																funcProps={
																	renewalProps
																}
															/>
														</section>
													</Col>
												</Row>
											</article>
										</ParallaxLayer>

										{/* Need the Footer Menu to display new features */}
										<Footer />

										<AvrsModal
											show={this.state.modalShow}
											setModalShow={this.setModalShow}
											isAuthenticated={
												this.props.isAuthenticated
											}
											userHasAuthenticated={
												this.props.userHasAuthenticated
											}
										/>
									</Parallax>
								</Container>
							</Container>
						</Container>
					)}
				/>
				{/* NOTE: 900px Small Mobile Md/LG-Views!!!*/}
				<Media
					query="(min-width: 899px) and (max-width: 1365px)"
					render={() => (
						<Container>
							<Container className="app-view-lander">
								<Container className="main-view-lander">
									<Parallax
										pages={3}
										scrolling={true}
										vertical
										ref={(ref) => (this.parallax = ref)}
									>
										{/* Covers the ss bg img */}
										<ParallaxLayer
											offset={0.06}
											speed={0.1}
											factor={6}
											style={
												{
													/*
												backgroundImage: url(
													"qat.dmv.camaro.jpg",
													"content",
													true
												),
												backgroundSize: "140%",
												*/
												}
											}
											className="ss-img"
											id="ss-root"
										>
											<article>
												<Image
													src="https://cdn-assets.quickautotags.com/images/content/qat.dmv.camaro.jpg"
													className="ss-bg-img-md"
													onLoad={
														this.handleImageLoad1
													}
												/>
											</article>
										</ParallaxLayer>
										{/* Covers the lines bg img */}
										<ParallaxLayer
											offset={1}
											speed={0.5}
											factor={4}
											style={
												{
													/*
												backgroundImage: url(
													"qat.dmv.camaro.jpg",
													"content",
													true
												),
												backgroundSize: "140%",
												*/
												}
											}
											className="lines-img"
											id="ss-root"
										>
											<article>
												<Image
													src="https://cdn-assets.quickautotags.com/images/content/ca-dmv-long-lines.jpg"
													className="lines-bg-img"
													onLoad={
														this.handleImageLoad2
													}
												/>
											</article>
										</ParallaxLayer>
										{/* Covers the porsche bg img */}
										<ParallaxLayer
											offset={2}
											speed={0.5}
											factor={4}
											style={
												{
													/*
												backgroundImage: url(
													"qat.dmv.camaro.jpg",
													"content",
													true
												),
												backgroundSize: "140%",
												*/
												}
											}
											className="lines-img"
											id="ss-root"
										>
											<article>
												<section className="porsche-img">
													<Image
														src="https://cdn-assets.quickautotags.com/images/content/ca-dmv-registration-renewals.jpg"
														className="porsche-bg-img"
														onLoad={
															this
																.handleImageLoad3
														}
													/>
												</section>
											</article>
										</ParallaxLayer>

										{/* Covers the header */}
										{/* Value Prop with Nav & AVRS */}
										<ParallaxLayer
											offset={0.61} // this can be changed specific to this view size
											speed={1}
											style={
												{
													//backgroundSize: "100%",
												}
											}
											className="value-prop"
										>
											<Navbar
												className="app-navi-bar"
												bg="light"
												variant="light"
												expand="md"
												onLoad={this.handleNavLoad}
											>
												<Row className="brand-lg container">
													<Col className="profile-img-col-lg">
														<Navbar.Brand
															as={NavLink}
															to="/"
															className="logo-element"
														>
															<Image
																src="https://cdn-assets.quickautotags.com/images/branding/Logo.QAT.png"
																className="App-logo-lg"
															/>
														</Navbar.Brand>
													</Col>
													<Col
														md={3}
														className="contact-num contact-num-style"
													>
														<FaPhoneAlt className="phone-icon" />
														<PhoneNumber
															number=" +18887230842"
															isLinked={true}
														/>
													</Col>
												</Row>
											</Navbar>

											<article className="header-cta-md">
												<Col>
													<h1
														style={
															hdrSpacingStyleObj
														}
													>
														Get your vehicle
														registered today
													</h1>
												</Col>
											</article>

											<article className="header-cta-icons">
												<Row
													className="icon-row"
													style={iconRowStyleObj}
												>
													<Col>
														<h3 className="header-cta-titles">
															Skip the DMV
															headache
														</h3>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/avoid-the-line-ca-dmv.png"
															className="App-logo-md"
														/>
													</Col>
													<Col>
														<h3 className="header-cta-titles">
															Register online or
															in person
														</h3>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/dmv-register-online-icon.png"
															className="App-logo-md"
														/>
													</Col>
													<Col>
														<h3 className="header-cta-titles">
															Fast and
															professional
														</h3>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/skip-the-line-rocket-dmv.png"
															className="App-logo-md"
														/>
													</Col>
												</Row>
												<Row className="btn-row">
													<Col
														md={3}
														className="btn-column"
													>
														<UiLoadBtn
															block
															size="md"
															className="modal-btn-md"
															style={
																buttonStyleObj
															}
															disabled={
																this.state
																	.isLoading
															}
															//variant="danger"
															type="button"
															onClick={this.setModalShow.bind(
																this,
																true
															)}
															isLoading={
																this.state
																	.isLoading
															}
															text="Renew Today"
															loadingText="Creating..."
														/>
													</Col>
												</Row>
											</article>

											<article
												className="header-cta-value-avrs"
												style={valuePropStyleObj}
											>
												<Row
													className="header-avrs-row"
													style={avrsRowHdrStyle}
												>
													<Col className="header-avrs-col-left-md">
														<h2
															style={
																h2StyleObject
															}
														>
															If You need any of
															the following we can
															help you save time
															and money
														</h2>

														<section>
															<ul
																style={
																	ulStyleObject
																}
															>
																<li
																	style={
																		liStyleObject
																	}
																>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Skip the DMV
																	lines & wait
																</li>
																<li>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Instantly
																	renew your
																	tags by
																	phone, text,
																	or email
																</li>
																<li>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Get a free
																	e-copy of
																	your
																	registration
																</li>
																<li>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Free
																	delivery of
																	your sticker
																	&
																	registration
																</li>
															</ul>
														</section>
													</Col>
													<Col className="header-avrs-col-right-md">
														<article>
															<header className="avrs-col-hdr-md">
																Get Started Here
															</header>
															<section>
																<AvrsFormPrimary
																	view="avrs-hdr"
																	funcProps={
																		renewalProps
																	}
																/>
															</section>
														</article>
													</Col>
												</Row>
											</article>

											<Col lg={11} className="lines-col">
												<article
													className="avoid-the-dmv-line"
													style={dmvLineStyleObj}
												>
													<h2>
														On average a trip to the
														DMV can take 45-130
														minutes
													</h2>
												</article>
											</Col>
										</ParallaxLayer>

										{/* Covers the 2ndary value prop under lines */}
										<ParallaxLayer
											offset={valueProp2Offset} // FIXME: This controls the 2ndary valueprop
											speed={1}
											style={{
												backgroundColor: "white",
												opacity: 1,
											}}
										>
											<Row>
												<Col
													lg={12}
													className="vp2-col"
												>
													<article className="vp2">
														<h2>
															Our Professional
															Agents will help you
															get registered and
															get you on your way
															today
														</h2>
													</article>
												</Col>
											</Row>

											<article className="header-cta-icons">
												<Row className="btn-row">
													<Col
														lg={3}
														className="btn-column"
													>
														<UiLoadBtn
															block
															size="lg"
															className="modal-btn"
															style={
																buttonStyleObj
															}
															disabled={
																this.state
																	.isLoading
															}
															//variant="danger"
															type="button"
															onClick={this.setModalShow.bind(
																this,
																true
															)}
															isLoading={
																this.state
																	.isLoading
															}
															text="Renew Today"
															loadingText="Creating..."
														/>
													</Col>
												</Row>
												<hr />
												<Row className="vp2-row">
													<Col
														lg={9}
														className="vp2-col"
													>
														<article className="vp2">
															<h2>
																Where we operate
															</h2>
															<h3>
																Register your
																vehicle
															</h3>
														</article>
													</Col>
												</Row>
												<Row
													className="vp2-row"
													style={iconRowStyleObj}
												>
													<Col>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/ca-dmv-online-icon.png"
															className="vp2-logo-lg"
														/>
														<h3 className="vp2-cta-titles">
															Online
														</h3>
													</Col>
													<Col>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/dmv-meeting.png"
															className="vp2-logo-lg"
														/>
														<h3 className="vp2-cta-titles">
															In person
														</h3>
													</Col>
													<Col>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/phone-email-icon.png"
															className="vp2-logo-lg"
														/>
														<h3 className="vp2-cta-titles">
															Phone or Email
														</h3>
													</Col>
												</Row>
											</article>
											<Row>
												<div style={googleMapStyle}>
													<GoogleMap />
												</div>
											</Row>

											<article
												className="ftr-cta-value-avrs"
												style={footerStyleObj}
											>
												<Row
													className="ftr-avrs-row"
													style={ftrHeightStyle}
												>
													<Col className="ftr-avrs-col-left">
														<h2
															className="ftr-hdr"
															style={
																h2StyleObject
															}
														>
															Contact Us
														</h2>

														<section>
															<ul
																style={
																	ulStyleObject
																}
															>
																<li
																	style={
																		liStyleObject
																	}
																>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Location
																</li>

																<li
																	style={{
																		paddingLeft:
																			"1.75em",
																	}}
																>
																	<a href="https://www.google.com/maps/place/5586+Mission+Boulevard,+Riverside,+CA+92509/@33.996608,-117.4090365,17z">
																		5586
																		Mission
																		Blvd
																		Riverside,
																		CA 92509
																	</a>
																</li>

																<li>
																	<FaRegCheckSquare className="checkbox-icon" />
																	<PhoneNumber
																		number=" +18887230842"
																		isLinked={
																			true
																		}
																		style={{
																			color:
																				"white",
																		}}
																	/>
																</li>
																<li>
																	<Link
																		to="/privacy-policy"
																		className="ftr-block"
																		onClick={
																			void 0
																		}
																		onTouchStart={() =>
																			""
																		}
																	>
																		<FaRegCheckSquare className="checkbox-icon" />
																		Privacy
																		Policy
																	</Link>
																</li>
																<li>
																	<Link
																		to="/terms-conditions"
																		className="ftr-block"
																		onClick={
																			void 0
																		}
																		onTouchStart={() =>
																			""
																		}
																	>
																		<FaRegCheckSquare className="checkbox-icon" />
																		Terms &
																		Conditions
																	</Link>
																</li>
															</ul>
														</section>
													</Col>
													<Col className="ftr-avrs-col-right porsche-right">
														<section>
															<AvrsFormSecondary
																view="porsche"
																funcProps={
																	renewalProps
																}
															/>
														</section>
													</Col>
												</Row>
											</article>
										</ParallaxLayer>

										{/* Need the Footer Menu to display new features */}
										<Footer />

										<AvrsModal
											show={this.state.modalShow}
											setModalShow={this.setModalShow}
											isAuthenticated={
												this.props.isAuthenticated
											}
											userHasAuthenticated={
												this.props.userHasAuthenticated
											}
										/>
									</Parallax>
								</Container>
							</Container>
						</Container>
					)}
				/>
				{/* NOTE: 1600px Desktop XL-Views!!!*/}
				<Media
					query="(min-width: 1366px)"
					render={() => (
						<Container>
							<Container className="app-view-lander">
								<Container className="main-view-lander">
									<Parallax
										pages={3}
										scrolling={true}
										vertical
										ref={(ref) => (this.parallax = ref)}
									>
										{/* Covers the ss bg img */}
										<ParallaxLayer
											offset={0.06}
											speed={0.1}
											factor={4}
											style={
												{
													/*
												backgroundImage: url(
													"qat.dmv.camaro.jpg",
													"content",
													true
												),
												backgroundSize: "140%",
												*/
												}
											}
											className="ss-img"
											id="ss-root"
										>
											<article>
												<Image
													src="https://cdn-assets.quickautotags.com/images/content/qat.dmv.camaro.jpg"
													className="ss-bg-img"
													onLoad={
														this.handleImageLoad1
													}
												/>
											</article>
										</ParallaxLayer>
										{/* Covers the lines bg img */}
										<ParallaxLayer
											offset={1}
											speed={0.5}
											factor={4}
											style={
												{
													/*
												backgroundImage: url(
													"qat.dmv.camaro.jpg",
													"content",
													true
												),
												backgroundSize: "140%",
												*/
												}
											}
											className="lines-img"
											id="ss-root"
										>
											<article>
												<Image
													src="https://cdn-assets.quickautotags.com/images/content/ca-dmv-long-lines.jpg"
													className="lines-bg-img"
													onLoad={
														this.handleImageLoad2
													}
												/>
											</article>
										</ParallaxLayer>
										{/* Covers the porsche bg img */}
										<ParallaxLayer
											offset={2}
											speed={0.5}
											factor={4}
											style={
												{
													/*
												backgroundImage: url(
													"qat.dmv.camaro.jpg",
													"content",
													true
												),
												backgroundSize: "140%",
												*/
												}
											}
											className="lines-img"
											id="ss-root"
										>
											<article>
												<section className="porsche-img">
													<Image
														src="https://cdn-assets.quickautotags.com/images/content/ca-dmv-registration-renewals.jpg"
														className="porsche-bg-img"
														onLoad={
															this
																.handleImageLoad3
														}
													/>
												</section>
											</article>
										</ParallaxLayer>

										{/* Covers the header */}
										{/* Value Prop with Nav & AVRS */}
										<ParallaxLayer
											offset={0.61}
											speed={1}
											style={
												{
													//backgroundImage: url(
													//"ca-happy-dmv-renewals.jpg",
													//"content",
													//true
													//),
													//pointerEvents: "none",
													//backgroundSize: "100%",
												}
											}
											className="value-prop"
										>
											<Navbar
												className="app-navi-bar"
												bg="light"
												variant="light"
												expand="lg"
												onLoad={this.handleNavLoad}
											>
												<Row className="brand-lg container">
													<Col className="profile-img-col-lg">
														<Navbar.Brand
															as={NavLink}
															to="/"
															className="logo-element"
														>
															<Image
																src="https://cdn-assets.quickautotags.com/images/branding/Logo.QAT.png"
																className="App-logo-lg"
															/>
														</Navbar.Brand>
													</Col>
													<Col
														lg={2}
														className="contact-num contact-num-style"
													>
														<FaPhoneAlt className="phone-icon" />
														<PhoneNumber
															number=" +18887230842"
															isLinked={true}
														/>
													</Col>
												</Row>
											</Navbar>

											<article className="header-cta">
												<Col>
													<h1
														style={
															hdrSpacingStyleObj
														}
													>
														Get your vehicle
														registered today
													</h1>
												</Col>
											</article>

											<article className="header-cta-icons">
												<Row
													className="icon-row"
													style={iconRowStyleObj}
												>
													<Col>
														<h3 className="header-cta-titles">
															Skip the DMV
															headache
														</h3>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/avoid-the-line-ca-dmv.png"
															className="App-logo-lg"
														/>
													</Col>
													<Col>
														<h3 className="header-cta-titles">
															Register online or
															in person
														</h3>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/dmv-register-online-icon.png"
															className="App-logo-lg"
														/>
													</Col>
													<Col>
														<h3 className="header-cta-titles">
															Fast and
															professional
														</h3>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/skip-the-line-rocket-dmv.png"
															className="App-logo-lg"
														/>
													</Col>
												</Row>
												<Row className="btn-row">
													<Col
														lg={3}
														className="btn-column"
													>
														<UiLoadBtn
															block
															size="lg"
															className="modal-btn"
															style={
																buttonStyleObj
															}
															disabled={
																this.state
																	.isLoading
															}
															//variant="danger"
															type="button"
															onClick={this.setModalShow.bind(
																this,
																true
															)}
															isLoading={
																this.state
																	.isLoading
															}
															text="Renew Today"
															loadingText="Creating..."
														/>
													</Col>
												</Row>
											</article>

											<article
												className="header-cta-value-avrs"
												style={valuePropStyleObj}
											>
												<Row
													className="header-avrs-row"
													style={avrsRowHdrStyle}
												>
													<Col className="header-avrs-col-left">
														<h2
															style={
																h2StyleObject
															}
														>
															If You need any of
															the following we can
															help you save time
															and money
														</h2>

														<section>
															<ul
																style={
																	ulStyleObject
																}
															>
																<li
																	style={
																		liStyleObject
																	}
																>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Skip the DMV
																	lines & wait
																</li>
																<li>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Instantly
																	renew your
																	tags by
																	phone, text,
																	or email
																</li>
																<li>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Get a free
																	e-copy of
																	your
																	registration
																</li>
																<li>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Free
																	delivery of
																	your sticker
																	&
																	registration
																</li>
															</ul>
														</section>
													</Col>
													<Col className="header-avrs-col-right">
														<article>
															<header className="avrs-col-hdr">
																Get Started Here
															</header>
															<section>
																<AvrsFormPrimary
																	view="avrs-hdr"
																	funcProps={
																		renewalProps
																	}
																/>
															</section>
														</article>
													</Col>
												</Row>
											</article>

											<Col lg={11} className="lines-col">
												<article
													className="avoid-the-dmv-line"
													style={dmvLineStyleObj}
												>
													<h2>
														On average a trip to the
														DMV can take 45-130
														minutes
													</h2>
												</article>
											</Col>
										</ParallaxLayer>

										{/* Covers the 2ndary value prop under lines */}
										<ParallaxLayer
											offset={valueProp2Offset}
											speed={1}
											style={{
												backgroundColor: "white",
												opacity: 1,
											}}
										>
											<Row>
												<Col
													lg={12}
													className="vp2-col"
												>
													<article className="vp2">
														<h2>
															Our Professional
															Agents will help you
															get registered and
															get you on your way
															today
														</h2>
													</article>
												</Col>
											</Row>

											<article className="header-cta-icons">
												<Row className="btn-row">
													<Col
														lg={3}
														className="btn-column"
													>
														<UiLoadBtn
															block
															size="lg"
															className="modal-btn"
															style={
																buttonStyleObj
															}
															disabled={
																this.state
																	.isLoading
															}
															//variant="danger"
															type="button"
															onClick={this.setModalShow.bind(
																this,
																true
															)}
															isLoading={
																this.state
																	.isLoading
															}
															text="Renew Today"
															loadingText="Creating..."
														/>
													</Col>
												</Row>
												<hr />
												<Row className="vp2-row">
													<Col
														lg={9}
														className="vp2-col"
													>
														<article className="vp2">
															<h2>
																Where we operate
															</h2>
															<h3>
																Register your
																vehicle
															</h3>
														</article>
													</Col>
												</Row>
												<Row
													className="vp2-row"
													style={iconRowStyleObj}
												>
													<Col>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/ca-dmv-online-icon.png"
															className="vp2-logo-lg"
														/>
														<h3 className="vp2-cta-titles">
															Online
														</h3>
													</Col>
													<Col>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/dmv-meeting.png"
															className="vp2-logo-lg"
														/>
														<h3 className="vp2-cta-titles">
															In person
														</h3>
													</Col>
													<Col>
														<Image
															src="https://cdn-assets.quickautotags.com/images/content/phone-email-icon.png"
															className="vp2-logo-lg"
														/>
														<h3 className="vp2-cta-titles">
															Phone or Email
														</h3>
													</Col>
												</Row>
											</article>
											<Row>
												<div style={googleMapStyle}>
													<GoogleMap />
												</div>
											</Row>

											<article
												className="ftr-cta-value-avrs"
												style={footerStyleObj}
											>
												<Row
													className="ftr-avrs-row"
													style={ftrHeightStyle}
												>
													<Col className="ftr-avrs-col-left">
														<h2
															className="ftr-hdr"
															style={
																h2StyleObject
															}
														>
															Contact Us
														</h2>

														<section>
															<ul
																style={
																	ulStyleObject
																}
															>
																<li
																	style={
																		liStyleObject
																	}
																>
																	<FaRegCheckSquare className="checkbox-icon" />
																	Location
																</li>

																<li
																	style={{
																		paddingLeft:
																			"1.75em",
																	}}
																>
																	<a href="https://www.google.com/maps/place/5586+Mission+Boulevard,+Riverside,+CA+92509/@33.996608,-117.4090365,17z">
																		5586
																		Mission
																		Blvd
																		Riverside,
																		CA 92509
																	</a>
																</li>

																<li>
																	<FaRegCheckSquare className="checkbox-icon" />
																	<PhoneNumber
																		number=" +18887230842"
																		isLinked={
																			true
																		}
																		style={{
																			color:
																				"white",
																		}}
																	/>
																</li>
																<li>
																	<Link
																		to="/privacy-policy"
																		className="ftr-block"
																		onClick={
																			void 0
																		}
																		onTouchStart={() =>
																			""
																		}
																	>
																		<FaRegCheckSquare className="checkbox-icon" />
																		Privacy
																		Policy
																	</Link>
																</li>
																<li>
																	<Link
																		to="/terms-conditions"
																		className="ftr-block"
																		onClick={
																			void 0
																		}
																		onTouchStart={() =>
																			""
																		}
																	>
																		<FaRegCheckSquare className="checkbox-icon" />
																		Terms &
																		Conditions
																	</Link>
																</li>
															</ul>
														</section>
													</Col>
													<Col className="ftr-avrs-col-right porsche-right">
														<section>
															<AvrsFormSecondary
																view="porsche"
																funcProps={
																	renewalProps
																}
															/>
														</section>
													</Col>
												</Row>
											</article>
										</ParallaxLayer>

										{/* Need the Footer Menu to display new features */}
										<Footer />

										<AvrsModal
											show={this.state.modalShow}
											setModalShow={this.setModalShow}
											isAuthenticated={
												this.props.isAuthenticated
											}
											userHasAuthenticated={
												this.props.userHasAuthenticated
											}
										/>
									</Parallax>
								</Container>
							</Container>
						</Container>
					)}
				/>
			</div>
		);
	}
}
