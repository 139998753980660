import React, { Component } from "react";
import { API } from "aws-amplify";
import Geosuggest from "react-geosuggest";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import UiLoadBtn from "../components/UiLoadBtn";
import "./AvrsModal.css";
import NativeStackTokenizer from "react-cardpointe-gateway";
import config from "../config";
import { Spring } from "react-spring/renderprops";
import Image from "react-bootstrap/Image";

/* global google */

export default class AvrsFormSecondary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      validated: false,
      modalVin: "",
      plate: "",
      zip: "",
      email: "",
      pmtAuth: null, // FIXME: PUT BACK TO NULL
      emvData: "",
      expiryDate: "",
      billingName: "",
      billingAddress: "",
      billingAddress2: "",
      billingCity: "",
      billingState: "",
      billingZipCode: "",
      renewalCity: "",
      renewalState: "",
      countyName: "",
      countyNum: "",
      rdf: false,
      fees: "",
      price: "Please wait, The CA-DMV is Loading the Fees Due Now...",
      shipToName: "",
      shipToAddress: "",
      shipToAddressLine2: "",
      shipToCity: "",
      shipToState: "",
      shipToZipCode: "",
      paymentReceipt: "",
      geoCodeURL: "",
      renewalStep: 0,
      loader: true,
      receiptLoaded: false,
      step2Receipt: "",
      isFocused: false,
      isFocusedPorsche: false,
    };

    this.renewalWorkFlow = this.renewalWorkFlow.bind(this);
    this.selectSuggestion = this.selectSuggestion.bind(this);
  }

  async componentDidMount() {
    if (this.state.renewalStep.toString() === "0") {
      this.setState({
        renewalStep: 1, // FIXME: PUT BACK TO 1
      });

      console.log("RENEWALSSSSSS: ", this.props.childProps);

      this.props.funcProps.setRenewalStep(1);
    }
  }

  async componentDidUpdate() {
    if (Number(this.state.price) > 0 && !isNaN(Number(this.state.price))) {
      if (this.state.receiptLoaded === false) {
        this.setState({
          receiptLoaded: true,
        });
        this.handlePaymentReceipt();
      }
    }
    // TODO: Need validate for non numbers or counties that dont exist
    if (
      this.state.zip === "" ||
      this.state.zip.length < 5 ||
      this.state.modalVin === "" ||
      this.state.modalVin.length < 3 ||
      this.state.plate === "" ||
      this.state.plate.length < 7
    ) {
      if (this.state.zip.length === 0 && this.state.countyNum.length > 0) {
        this.setState({
          countyNum: "",
        });
      }
      // eslint-disable-next-line
      console.log("Waiting for user to enter an appropriate data");
    } else {
      if (this.state.zip.length >= 5) {
        // eslint-disable-next-line

        console.log("THIS IS ZIP LEN", this.state.zip.length);
        try {
          if (this.state.countyNum === "") {
            if (this.state.zip.length === 5 && this.state.loader === true) {
              this.setState({
                loader: false,
                isLoading: true,
              });
            }
            const countyCode = await this.getCountyCode(this.state.zip);

            if (countyCode.CountyNumber) {
              this.setState({
                countyNum: countyCode.CountyNumber,
                renewalCity: countyCode.City,
                renewalState: countyCode.State,
              });
            } else {
              // eslint-disable-next-line
              alert(
                "QuickAutoTags SystemAdmin Notification:\n\nThis platform can only process California Vehicle Registration Renewals.\n\nPlease call us at: 1 (888) 723 - 0842 to express your interest in becoming a BPA Certified Auto Tag Franchise."
              );
              this.setState({
                countyNum: "ERROR",
              });
            }

            if (this.state.loader === false) {
              this.setState({
                loader: true,
                isLoading: false,
              });
            }
          }
          // eslint-disable-next-line
          //console.log("Here is the VIN: ", this.state.modalVin);
          // eslint-disable-next-line
          //console.log("Here is the Plate: ", this.state.plate);
        } catch (err) {
          // if there is an error with the county code then set state to null || ERROR??
          this.setState({
            zip: "",
            countyNum: "ERROR",
          });
          // eslint-disable-next-line
          alert(
            "QuickAutoTags SystemAdmin Notification:\n\nThis platform can only process California Vehicle Registration Renewals.\n\nPlease call us at: 1 (888) 723 - 0842 to express your interest in becoming a BPA Certified Auto Tag Franchise."
          );
          // eslint-disable-next-line
          console.log("User provided Invalid ZipCode: ", err);
        }
      }
    }
  }

  // FIXME: may need to delete or merge this into more
  //        efficient validation schemes
  // Need to make sure the form is filled in with information
  validateForm() {
    let b = false;
    // eslint-disable-next-line
    //console.log("validateForm called!!!!");

    // If on step 1 && dealer has name && name is NaN && valid address
    // then bool is true

    if (this.state.renewalStep.toString() === "1") {
      if (
        this.state.modalVin.length > 0 &&
        this.state.plate.length > 0 &&
        this.state.zip.length > 0 &&
        this.state.countyNum !== "ERROR" &&
        this.state.countyNum.length > 0
      ) {
        if (this.state.modalVin.length === 3 && !isNaN(this.state.modalVin)) {
          b = true;
        }
      }
    }

    //validate fields in step 2 before submitting form
    if (this.state.renewalStep.toString() === "2") {
      if (
        !isNaN(this.state.price) &&
        this.state.email.length > 7 &&
        this.state.billingName.length > 5
      ) {
        b = true;
      }
    }

    // TODO: Will there even be a step 3 here?
    //validate fields in step 3 before submitting form
    if (this.state.renewalStep.toString() === "3") {
      if (
        this.state.emvData.expiryDate &&
        this.state.emvData.expiryDate.length > 0 &&
        this.state.billingAddress.length > 0 &&
        this.state.billingCity.length > 0 &&
        this.state.billingState.length > 0 &&
        this.state.billingZipCode.length > 0
      ) {
        // if false then confirmation is turned off!
        b = true;
      }
    }

    //validate fields in step 3 before submitting form
    if (this.state.renewalStep.toString() === "4") {
      if (
        this.state.shipToZipCode !== "" &&
        this.state.shipToAddress !== "" &&
        typeof this.state.shipToCity !== "undefined" &&
        typeof Math.floor(this.state.shipToZipCode) === "number" &&
        Math.floor(this.state.shipToZipCode) > 0
      ) {
        // if false then confirmation is turned off!
        b = true;
      }
    }

    // return the bool value. true if form is valid or false if
    // the form is invalid...
    return b;
  }

  handleFocus = (e) => {
    if (this.state.isFocused === false) {
      this.setState({
        isFocused: true,
      });
    }
  };

  handleBlur = (e) => {
    if (this.state.isFocused === true) {
      this.setState({
        isFocused: false,
      });
    }
  };

  handleFocusPorsche = (e) => {
    if (this.state.isFocusedPorsche === false) {
      this.setState({
        isFocusedPorsche: true,
      });
    }
  };

  handleBlurPorsche = (e) => {
    if (this.state.isFocusedPorsche === true) {
      this.setState({
        isFocusedPorsche: false,
      });
    }
  };

  handleChange = (event) => {
    if (
      event.target.id === "plate" ||
      event.target.id === "modalVin" ||
      event.target.id === "billingName" ||
      event.target.id === "billingAddress" ||
      event.target.id === "billingAddress2" ||
      event.target.id === "billingCity"
    ) {
      this.setState({
        [event.target.id]: event.target.value.toUpperCase(),
      });
    } else {
      this.setState({
        [event.target.id]: event.target.value,
      });
    }
  };

  handlePaymentReceipt = (event) => {
    this.setState({
      // set button spinner state to loading
      // while we wait for async responses
      isLoading: true,
    });

    const process =
      this.roundToPrecision(this.state.price, 0.05) -
      this.roundToPrecision(
        this.state.fees.fees_details["by-type"][0].adjusted,
        0.05
      ) -
      31.0;

    this.setState({
      paymentReceipt: ` California DMV Fees:     $ ${this.state.fees.fees_details[
        "by-type"
      ][0].adjusted.toString()} \n QAT Service Fees:         $ 31.00 \n Fulfillment Fees:             $ ${process.toFixed(
        3
      )} \n =========================== \n Credit Card PAID:           $ ${this.roundToPrecision(
        this.state.price,
        0.05
      ).toFixed(3)}`,

      step2Receipt: ` California DMV Fees:     $ ${this.state.fees.fees_details[
        "by-type"
      ][0].adjusted.toString()} \n QAT Service Fees:         $ 31.00 \n Fulfillment Fees:             $ ${process.toFixed(
        3
      )} \n ====================== \n`,
    });

    this.setState({
      // set button spinner state to loading
      // while we wait for async responses
      isLoading: false,
    });
  };

  /* NOTE:
   * @function userEmvData
   * @param emvData
   * Parent component must implement function
   * to pass emvData returned from child into
   * state.
   */

  userEmvData = (emvData) => {
    this.setState({
      emvData: emvData,
    });
  };

  // eslint-disable-next-line
  regexAddress(suggestion) {
    if (suggestion.length > 0) {
      // FIXME: When address is 1085-1087 Boylston St Regex fails at 1085-
      let result = suggestion.split(",")[0];
      return result;
    }
  }

  // eslint-disable-next-line
  zipCodeSearch = async (event) => {
    let formattedAddress;
    let url = this.state.geoCodeURL;

    // NOTE: FIXED THE ERROR WITH PARSING THE JSON RESPONSE
    // Need to get address data back from google
    // to pull zipcode data
    // eslint-disable-next-line
    await fetch(url)
      .then((response) => response.json())
      // eslint-disable-next-line
      .then(
        (data) =>
          (formattedAddress = data.results[0].formatted_address.split(", "))
      );

    let city = formattedAddress[formattedAddress.length - 3];
    let state = formattedAddress[formattedAddress.length - 2].split(" ")[0];
    let zip = formattedAddress[formattedAddress.length - 2].split(" ")[1];

    console.log("HERE IS FORMAT: ", formattedAddress);

    this.setState({
      shipToCity: city,
      shipToState: state,
      shipToZipCode: formattedAddress ? 0 : zip,
    });

    // 711 East Okeechobee Road, Hialeah, FL 33010, USA
  };

  async selectSuggestion(suggestion) {
    try {
      this.setState({
        shipToAddress: suggestion.label,
      });

      let apiKey = config.Places_API_KEY;
      let result = this.regexAddress(this.state.shipToAddress);

      this.setState({
        shipToAddress: result,
      });

      let url = "https://maps.googleapis.com/maps/api/geocode/json?address=";

      let address = this.state.shipToAddress.split(" ");

      let query = "";

      for (let i = 0; i < address.length; i++) {
        if (i === address.length - 1) {
          query = query + address[i];
        } else {
          query = query + address[i] + "+";
        }
      }

      // build the google api url we need to get location data
      url += query + "&key=" + apiKey;

      if (url.length > 0) {
        this.setState({
          geoCodeURL: url,
        });
      }

      // NOTE: call the zipCodeSearch function so that
      //       we can extract location data from google
      //       response inside of formatted_address field
      await this.zipCodeSearch();
    } catch (err) {
      this.setState({
        shipToAddress: "",
      });
    }
  }

  handleError = (err) => {
    // eslint-disable-next-line
    const code = /[^\/]+(?=\:)/g;
    // eslint-disable-next-line
    const msg = /\-(.*)/g;

    let codeAr = code.exec(err);
    let msgAr = msg.exec(err);

    console.log("The value of REGEX ERROR CODE is " + codeAr[0]);
    console.log("The value of REGEX ERROR MSG is " + msgAr[0]);

    return [codeAr[0], msgAr[0]];
  };

  handleGetFees = async () => {
    // Quick Auto Tags
    const profitMargin = 31.0;
    const cogsICP = 1.035;

    this.setState({
      // set button spinner state to loading
      // while we wait for async responses
      isLoading: true,
    });

    try {
      // call cognito with amplify api
      // TODO: Make this call to get fees and build conditions to handle errors!!!!
      const { status, message, error, data } = await this.getAvrsFees();

      // Sanity Check on response data coming back
      console.log("This is the response status: ", status);
      console.log("This is the response message: ", message);
      console.log("THIS IS THE ERROR: ", error);
      console.log("This is the response data111: ", data);

      if (status === "success") {
        console.log(
          "STATE PRICE VALUE PROFIT TYPE: ",
          data.fees_details["by-type"][0].adjusted
        );
      }

      let dmvErrorCode = "";
      let dmvErrorMsg = "";

      if (status === "fail") {
        dmvErrorCode = this.handleError(error)[0];
        dmvErrorMsg = this.handleError(error)[1];
      }

      console.log("THIS IS the DMV ERROR CODE TYPE: ", typeof dmvErrorCode);
      console.log("THIS IS the DMV ERROR MSG TYPE: ", typeof dmvErrorMsg);

      console.log("THIS IS the DMV ERROR CODE: ", dmvErrorCode);
      console.log("THIS IS the DMV ERROR MSG: ", dmvErrorMsg);

      // NOTE: Check For RDF
      if (
        status === "fail" &&
        (dmvErrorMsg !== " - SMOG CERT REQUIRED" || dmvErrorCode !== "D365")
        //dmvErrorCode !== "D363" ||
        //dmvErrorCode !== "D364" ||
        //dmvErrorCode !== "D366")
      ) {
        this.setState({
          // turn on loading during async
          isLoading: true,
          // set state with fees data
          price: error,
        }); // FIXME: NEED TO ADD ALL OF THE ERROR CODES!!!!
      }

      // NOTE: Check For RDF
      if (
        (status === "fail" && dmvErrorMsg === " - SMOG CERT REQUIRED") ||
        (status === "fail" && dmvErrorCode === "D365") ||
        (status === "fail" && dmvErrorCode === "D108")
      ) {
        this.setState({
          // turn on loading during async
          isLoading: true,
          // set state with fees data
          fees: message,
          rdf: true,
          price:
            error +
            ". Please wait while we obtain and load your updated fees...",
        });

        // NOTE: THIS IS RDF PROCESSING HERE!!!!
        await this.processRdf();
      }

      // NOTE: NO RDF Straight Renewal No Errors
      if (status === "success") {
        console.log("This is the response status: ", status);
        console.log("This is the response message: ", message);
        console.log("This is the response error: ", error);
        console.log("This is the response data: ", data);

        this.setState({
          // turn on loading during async
          isLoading: true,
          // set state with fees data
          fees: data,
          price: this.roundToPrecision(
            (((data.fees_details["by-type"][0].adjusted + profitMargin) * 100) /
              100) *
              cogsICP,
            0.05
          ),
        });

        console.log("This is the response with DATA: ", data);
        console.log(
          "This is the response with DEAL_ID: ",
          this.state.fees.deal_id
        );
        console.log(
          "This is the response with FEES: ",
          this.state.fees.fees_details["by-type"][0].adjusted
        );
        console.log(
          "This is the response with FEESTYPE: ",
          typeof this.state.fees.fees_details["by-type"][0].adjusted
        );

        console.log(
          "This is the response with ITEMS: ",
          this.state.fees.fees_details["by-type"][0].items
        );
      }
    } catch (err) {
      // eslint-disable-next-line
      alert("ERROR in Renewals GetFees: " + err);
    }

    this.setState({
      // turn off loading when async complete
      isLoading: false,
    });
  };

  /*
   *  @function buy() authorizes and captures a transaction
   *
   *  @params need to send to card-pointe gateway for processing

    body:
      {
          "merchid": "{{merchid}}",
          "amount": "1.12",
          "expiry": "1223",
          "account": "{{account}}",
          "currency": "{{currency}}",
          "receipt": "y",
          "bin": "y",
          "capture": "y",
          "name":"{{name}}",
          "address":"{{address}}",
          "address2":"{{address2}}",
          "city":"{{city}}",
          "postal":"{{postal}}",
          "region":"{{region}}",
          "country":"{{country}}",
          "orderid": "14889199",
          "ecomind":"{{ecomind}}"
      }

      Need to Confirm Auth then Complete REGULAR TXN With this endpoint:L
      https://renew.qatcrm.com/ca-dmv/services/completeDeal.php?deal_id=18598892


      When Deal Complete then send PDF Receipt with this:
      https://renew.qatcrm.com/ca-dmv/services/getReceipt.php?deal_id=18598892&user_email=uni@quickdmvservices.com&admin_email=quickautotagsreceipt@gmail.com


  */
  buy = async () => {
    this.setState({
      isLoading: true,
    });

    /*
     *  NOTE: THIS IS TEMP CALL TO MERCHANT INQUIRY
     *
     */
    const merchant = await API.get(
      "payments",
      "/payments/card-pointe-gateway/merchant-inquiry"
    );
    console.log("THIS IS MERCH data: ", merchant.data);
    console.log("THIS IS MERCH ID: ", merchant.data.merchid);
    console.log("token: ", this.state.emvData.token);
    console.log("amount: ", this.state.price);

    try {
      if (
        this.state.pmtAuth !== "" ||
        this.state.pmtAuth.account !== this.state.emvData.token
      ) {
        const purchaseInfo = {
          body: {
            merchid: merchant.data.merchid,
            amount: this.state.price, // "1.00", // FIXME: IN PRODUCTION THIS MUST BE this.state.price
            expiry: this.state.expiryDate,
            account: this.state.emvData.token,
            currency: "USD",
            receipt: "y",
            bin: "y",
            capture: "y",
            name: this.state.billingName,
            address: this.state.billingAddress,
            address2: this.state.billingAddress2,
            city: this.state.billingCity,
            postal: this.state.billingZipCode,
            region: this.state.billingState,
            country: "US",
            orderid: "QAT-" + this.state.fees.deal_id,
            ecomind: "E",
          }, //, // replace this with attributes you need
          //headers: {} // OPTIONAL
        };

        console.log(
          "This is the ORDERID with DEAL_ID: ",
          this.state.fees.deal_id
        );

        console.log("This is PURCHASE INFO: ", purchaseInfo);
        // Send the txn to server
        //await API.post("payments", "/payments/card-pointe-gateway/purchase-item");
        const pmtAuth = await API.put(
          "payments",
          "/payments/card-pointe-gateway/purchase-item",
          purchaseInfo
        );

        console.log("This is the payment auth response: ", pmtAuth.data);

        this.setState({
          pmtAuth: pmtAuth.data,
        });

        const authorizedTxn = this.state.pmtAuth.respcode;

        if (
          authorizedTxn.toString() === "000" ||
          authorizedTxn.toString() === "003" ||
          authorizedTxn.toString() === "00" ||
          (authorizedTxn.toString() === "001" &&
            this.state.pmtAuth.respproc === "AMEX") ||
          (authorizedTxn.toString() === "0" &&
            this.state.pmtAuth.respproc === "FOMA") ||
          (authorizedTxn.toString() === "0" &&
            this.state.pmtAuth.respproc === "FDMS") ||
          (authorizedTxn.toString() === "A00" &&
            this.state.pmtAuth.respproc === "FDMS") ||
          (authorizedTxn.toString() === "00" &&
            this.state.pmtAuth.respproc === "PPS")
        ) {
          console.log("READY TO CLOSE DEAL_ID");

          // FIXME: NEED TO CALL ONLY OF STRAIGHT RENEWAL
          // FIXME: NEED A CONDITION FOR RDF
          if (!this.state.rdf) {
            // NOTE: Step 1. Complete Normal Txn Here
            const dealStatus = await this.completeDeal();

            if (
              dealStatus.status === "success" &&
              dealStatus.data.deal_status === "C"
            ) {
              console.log("RECEIPT SEND _ START");
              await this.sendPdfReceipt("y");
              console.log("RECEIPT SEND _ END");
            }
          }

          if (this.state.rdf) {
            // NOTE: Step 2. Complete RDF Txn Here
            const dealStatus = await this.postFeesRdf();

            if (
              dealStatus.data["deal-transactions"][0]["deal-status"] === "FP" &&
              dealStatus.data["deal-transactions"][0].status ===
                "response-valid"
            ) {
              console.log("RECEIPT SEND _ START");
              await this.sendPdfReceipt("y");
              console.log("RECEIPT SEND _ END");
            }
          }

          //then complete the following:
          // Step 1:
          //    TODO: - [ ] create function to push txn data to db for RevReconcile later
          //    const txnRecord = this.state.pmtAuth.TxnAuth.transaction;
          //    ie: `this.recordTransaction(txnRecord)`
          //
          // Step 2:
          /*
            check if txn RDF or not. might need to implement a flag

            TODO: - [ ] create a function to close the transaction
            ie: `this.closeAvrsTxn(RDF=false)`

           Step 3:

            call closeTxn function

            TODO: - [ ] create a function to send the receipt by calling the receipt endpoint
            ie: `this.sendReceipt(deal_id)`
        */
        } else if (
          authorizedTxn.toString() !== "000" ||
          authorizedTxn.toString() !== "003" ||
          authorizedTxn.toString() !== "00" ||
          (authorizedTxn.toString() !== "001" &&
            this.state.pmtAuth.respproc === "AMEX") ||
          (authorizedTxn.toString() !== "0" &&
            this.state.pmtAuth.respproc === "FOMA") ||
          (authorizedTxn.toString() !== "0" &&
            this.state.pmtAuth.respproc === "FDMS") ||
          (authorizedTxn.toString() !== "A00" &&
            this.state.pmtAuth.respproc === "FDMS") ||
          (authorizedTxn.toString() !== "00" &&
            this.state.pmtAuth.respproc === "PPS")
        ) {
          const alrt = this.state.pmtAuth.resptext;
          // eslint-disable-next-line
          alert(
            "Transaction is, or was previously Declined. Please try another card:" +
              alrt
          );
          // FIXME: Since the action is triggered it goes to next workflow step!!!!!
          // Need to stay in same workflow step on decline
          // FIXME:
          // FIXME:
          // eslint-disable-next-line
          let name = prompt(
            "Please enter a new Cardholder name:",
            "Credit Card name goes here!"
          );

          this.setState({
            billingName: name,
          });
        }
      } else {
        console.log("Cycling through event loop");
      }
    } catch (err) {
      console.log("The call to post payment failed: ", err);
    }

    this.setState({
      isLoading: false,
    });
  };

  async getCountyCode(zipCode) {
    return await API.get("avrs-utilities", `/avrs-transactions/${zipCode}`);
  }

  async getAvrsFees() {
    this.setState({
      // turn on loading during async
      isLoading: true,
    });

    console.log("Here is the GetFees VIN: ", this.state.modalVin);
    console.log("Here is the GetFees Plate: ", this.state.plate);
    console.log("Here is the GetFees county: ", this.state.countyNum);
    console.log("Here is the GetFees county: ", this.state.zip);

    try {
      const url = await API.get(
        "avrs-transactions",
        `/ca-dmv/services/getFees.php?zip=${this.state.zip}&county=${this.state.countyNum}&vin=${this.state.modalVin}&plate=${this.state.plate}&insurance=Y`
      );

      this.setState({
        isLoading: false,
      });

      return url;
    } catch (err) {
      console.log("THIS ERROR MAYBE: ", err);
      return err;
    }
  }

  async getAvrsFeesRdf() {
    this.setState({
      isLoading: true,
    });
    console.log("Here is the GetFees VIN: ", this.state.modalVin);
    console.log("Here is the GetFees Plate: ", this.state.plate);
    console.log("Here is the GetFees county: ", this.state.countyNum);
    console.log("Here is the GetFees county: ", this.state.zip);

    try {
      const url = await API.get(
        "avrs-transactions",
        `/ca-dmv/services/getFeesSmog.php?zip=${this.state.zip}&county=${this.state.countyNum}&vin=${this.state.modalVin}&plate=${this.state.plate}&insurance=Y`
      );
      this.setState({
        isLoading: false,
      });
      return url;
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
      });
      return err;
    }
  }

  async processRdf() {
    this.setState({
      isLoading: true,
    });
    // Quick Auto Tags
    const profitMargin = 31.0;
    const cogsICP = 1.035;

    const { status, message, error, data } = await this.getAvrsFeesRdf();

    // Sanity Check on response data coming back
    console.log("This is the RDF response status: ", status);
    console.log("This is the RDF response message: ", message);
    console.log("This is the RDF response error: ", error);
    console.log("This is the RDF response data: ", data);

    if (status === "fail") {
      this.setState({
        // set state with fees data
        price: error,
      });
    } else {
      console.log("This is the RDF response status: ", status);
      console.log("This is the RDF response message: ", message);
      console.log("This is the RDF response error: ", error);
      console.log("This is the RDF response data: ", data);

      const res = data;

      this.setState({
        // set state with fees data
        fees: res,
        rdf: true,
        price: this.roundToPrecision(
          (res.fees_details["by-type"][0].adjusted + profitMargin) * cogsICP,
          0.05
        ),
      });

      console.log("This is the response with PRICE: ", res);

      console.log("This is the RDF response with DATA: ", this.state.fees);

      console.log("This is the response with PRICE: ", data);
      console.log(
        "This is the response with DEAL_ID: ",
        this.state.fees.deal_id
      );
      console.log(
        "This is the response with FEES: ",
        this.state.fees.fees_details["by-type"][0].adjusted
      );
      console.log(
        "This is the response with FEESTYPE: ",
        typeof this.state.fees.fees_details["by-type"][0].adjusted
      );

      console.log(
        "This is the response with ITEMS: ",
        this.state.fees.fees_details["by-type"][0].items
      );

      console.log("This is the response with RDF state: ", this.state.rdf);
    }
    this.setState({
      isLoading: false,
    });
  }

  async completeDeal() {
    this.setState({
      isLoading: true,
    });
    console.log("Here is the ORDER_ID ", this.state.fees.deal_id);

    try {
      const url = await API.get(
        "avrs-transactions",
        `/ca-dmv/services/completeDeal.php?deal_id=${this.state.fees.deal_id}`
      );
      this.setState({
        isLoading: false,
      });
      return url;
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
      });
      return err;
    }
  }

  async postFeesRdf() {
    this.setState({
      isLoading: true,
    });
    console.log("Here is the ORDER_ID ", this.state.fees.deal_id);

    try {
      const url = await API.get(
        "avrs-transactions",
        `/ca-dmv/services/postFees.php?deal_id=${this.state.fees.deal_id}`
      );
      this.setState({
        isLoading: false,
      });
      return url;
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
      });
      return err;
    }
  }

  async sendPdfReceipt(cust) {
    this.setState({
      isLoading: true,
    });
    console.log("Here is the PDF RECEIPT ORDER_ID ", this.state.fees.deal_id);
    const dmvFee = this.state.fees.fees_details["by-type"][0].adjusted;

    const process =
      this.roundToPrecision(this.state.price, 0.05) -
      this.roundToPrecision(
        this.state.fees.fees_details["by-type"][0].adjusted,
        0.05
      ) -
      31.0;

    console.log("Here is TOTAL PRICE PAID ", this.state.price);

    console.log(
      "Here is TOTAL FEES TO AVRS ",
      this.state.fees.fees_details.adjusted
    );

    console.log("Here is TOTAL FULFILLMENT ", process);

    console.log("Here is TOTAL PAID TO DMV ", dmvFee);

    console.log("WHAT IS CUST: ", cust);

    try {
      let shipLine2 = "";

      if (this.state.shipToAddressLine2 === "") {
        shipLine2 = "No Unit or Suite";
      } else {
        shipLine2 = this.state.shipToAddressLine2;
      }

      if (cust === "y") {
        return await API.get(
          "avrs-transactions",
          `/ca-dmv/services/getReceipt.php?deal_id=${
            this.state.fees.deal_id
          }&user_email=${
            this.state.email
          }&admin_email=quickautotagsreceipt@gmail.com&total=${
            this.state.price
          }&process=${process.toFixed(3)}&dmv=${this.state.fees.fees_details[
            "by-type"
          ][0].adjusted.toString()}&cust=${cust}`
        );
        //return url;
      } else if (
        cust === "n" &&
        typeof Math.floor(this.state.shipToZipCode) === "number" &&
        Math.floor(this.state.shipToZipCode) > 0
      ) {
        return await API.get(
          "avrs-transactions",
          `/ca-dmv/services/getReceipt.php?deal_id=${
            this.state.fees.deal_id
          }&user_email=${
            this.state.email
          }&admin_email=quickautotagsreceipt@gmail.com&total=${
            this.state.price
          }&process=${process.toFixed(3)}&dmv=${this.state.fees.fees_details[
            "by-type"
          ][0].adjusted.toString()}&cust=${cust}&shippingaddress=${encodeURI(
            this.state.shipToAddress
          )}&shippingline2=${shipLine2}&shippingcity=${
            this.state.shipToCity
          }&zip=${this.state.shipToZipCode}`
        );
        //return url;
      }
      return 0;
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
      });
      return err;
    }
  }

  /*
   * @function roundToPrecision
   * @params x, precision
   *
   * @return precise result
   *
   */
  roundToPrecision = (x, precision) => {
    let y = +x + (precision === undefined ? 0.5 : precision / 2);
    return y - (y % (precision === undefined ? 1 : +precision));
  };

  // TODO: Need functionality to handle state changes when button OnClick
  async renewalWorkFlow() {
    if (this.state.renewalStep.toString() === "1") {
      console.log("THIS IS Tag FIELD - We Just Sumbitted Tag");
      this.setState({
        renewalStep: 2,
      });

      try {
        this.props.funcProps.setRenewalStep(2);
      } catch (err) {
        console.log("UPDATING CARD");
        console.log("This is your NativeStackToken: ", this.state.emvData);
      }

      // get avrs DMV fees loading in state
      // and render in UI
      this.handleGetFees();
    }

    if (this.state.renewalStep.toString() === "2") {
      console.log("THIS IS EMAIL & FEES FIELD - We Just Gave EMAIL");
      // FIXME: fees displayed && email collected
      this.setState({
        renewalStep: 3,
      });
      this.props.funcProps.setRenewalStep(3);
    }

    if (this.state.renewalStep.toString() === "3") {
      console.log("THIS IS PAYMENT FIELD - We Just Clicked to PAY");
      // console.log("THIS IS STEP3");
      /* FIXME: Function to trigger the AuthZ & Capture of a txn

                buy()

                @return Authorization Response

      */
      this.handlePaymentReceipt();
      // Need to submit transaction to backend
      try {
        let by = await this.buy();

        console.log("This is BUY result: ", typeof by);
        if (typeof by !== "undefined") {
          this.setState({
            renewalStep: 4, // send to shipping page
          });
          this.props.funcProps.setRenewalStep(4);
        } else if (typeof by === "undefined") {
          this.setState({
            renewalStep: 6, // send to failure page
          });
          this.props.funcProps.setRenewalStep(5);
        }
      } catch (err) {
        this.setState({
          renewalStep: 6, // send to failure page
        });
        this.props.funcProps.setRenewalStep(6);
        console.log("THIS IS PAYMENT ERR", err);
      }
    }

    if (this.state.renewalStep.toString() === "4") {
      console.log("THIS IS SHIPPING FIELD - We Just Clicked to Ship");
      // console.log("THIS IS STEP4");
      // FIXME: shipping data here
      if (
        typeof Math.floor(this.state.shipToZipCode) === "number" &&
        Math.floor(this.state.shipToZipCode) > 0
      ) {
        console.log(
          "THIS IS TYPEOF zip: ",
          typeof Math.floor(this.state.shipToZipCode)
        );
        console.log("THIS IS TYPEOF city: ", typeof this.state.shipToCity);

        await this.sendPdfReceipt("n");

        this.setState({
          validated: false,
          modalVin: "",
          plate: "",
          zip: "",
          email: "",
          pmtAuth: null, // FIXME: PUT BACK TO NULL
          emvData: "",
          expiryDate: "",
          billingName: "",
          billingAddress: "",
          billingAddress2: "",
          billingCity: "",
          billingState: "",
          billingZipCode: "",
          renewalCity: "",
          renewalState: "",
          countyName: "",
          countyNum: "",
          rdf: false,
          fees: "",
          price: "Please wait, The CA-DMV is Loading the Fees Due Now...",
          shipToName: "",
          shipToAddress: "",
          shipToAddressLine2: "",
          shipToCity: "",
          shipToState: "",
          shipToZipCode: "",
          paymentReceipt: "",
          geoCodeURL: "",
          renewalStep: 5,
        });
      } else {
        // eslint-disable-next-line
        alert(
          "Please enter your Shipping Address to receive your renewed sticker!"
        );
      }

      this.setState({
        isLoading: false,
      });
    }

    // this is triggered from step5 UI onClick()
    if (this.state.renewalStep.toString() === "5") {
      // FIXME: NEED TO TEST!!!!
      this.setState({
        renewalStep: 0, // send to failure page
      });
      // NOTE: This needs to go to step5
      window.location.reload();
    }

    if (this.state.renewalStep.toString() === "6") {
      this.setState({
        renewalStep: 0, // send to failure page
      });
      // NOTE: This needs to go to step6
      window.location.reload();
    }
    //this.props.setModalShow(false);
  }

  renderCollectData() {
    const { validated } = this.state.validated;

    console.log("THIS IS VIEW: ", this.props.view);

    return (
      <Container
        validated={validated}
        className="main-form-modal"
        //show={this.props.show}
        //onHide={this.props.setModalShow.bind(this, false)}
        size="md"
        //aria-labelledby="contained-modal-title-vcenter"
        //centered
        //scrollable
      >
        <article>
          <p className="p-view-renewal">
            Please provide your vehicle registration details to obtain the total
            fees you owe the CA DMV for the renewal of your registration.
          </p>

          <Container className="renewals">
            {/* Start Form for step 1 here!! */}
            {/* onSubmit={ this.handleSubmit } */}
            <Form className="form-renewals" noValidate validated={validated}>
              {/* This is the customer name */}
              <Form.Group controlId="modalVin">
                <Row className="vin-row-field">
                  <Col xs={2} className="vin-col-1">
                    <Form.Label>VIN</Form.Label>
                  </Col>
                  <Col className="vin-col-2">
                    <Form.Control
                      as="input"
                      className="mcase-fields"
                      size="lg"
                      value={this.state.modalVin}
                      onChange={this.handleChange}
                      onFocus={this.handleFocusPorsche}
                      onBlur={this.handleBlurPorsche}
                      required
                    />
                  </Col>
                </Row>

                <Row className="muting-text-views">
                  <Form.Text className="text-muted-view">
                    Please enter the last 3 digits of your Vehicle ID Number
                    (VIN).
                  </Form.Text>
                </Row>
              </Form.Group>

              {/* This is the customer name */}
              <Form.Group controlId="plate">
                <Row className="plate-row-field">
                  <Col xs={2} className="plate-col-1">
                    <Form.Label>Plate</Form.Label>
                  </Col>
                  <Col className="plate-col-2">
                    <Form.Control
                      as="input"
                      className="mcase-fields"
                      size="lg"
                      value={this.state.plate}
                      onChange={this.handleChange}
                      onFocus={this.handleFocusPorsche}
                      onBlur={this.handleBlurPorsche}
                      required
                    />
                  </Col>
                </Row>
                <Row className="muting-text-views">
                  <Form.Text className="text-muted-view">
                    Please enter your License Plate Number.
                  </Form.Text>
                </Row>
              </Form.Group>

              {/* This is the customer name */}
              <Form.Group controlId="zip">
                <Row className="zip-row-field">
                  <Col xs={2} className="zip-col-1">
                    <Form.Label>Zip</Form.Label>
                  </Col>
                  <Col className="zip-col-2">
                    <Form.Control
                      as="input"
                      className="mcase-fields"
                      size="lg"
                      value={this.state.zip}
                      onChange={this.handleChange}
                      onFocus={this.handleFocusPorsche}
                      onBlur={this.handleBlurPorsche}
                      required
                    />
                  </Col>
                </Row>
                <Row className="muting-text-views">
                  <Form.Text className="text-muted-view">
                    Please enter the ZipCode where this tag is registered to.
                  </Form.Text>
                </Row>
              </Form.Group>
            </Form>
          </Container>
        </article>

        {/* this.props.setModalShow.bind(this, false) */}
        <footer className="ftr-btn-wide">
          <UiLoadBtn
            block
            size="lg"
            className="renew-btn-views"
            disabled={!this.validateForm()}
            //variant="success"
            onClick={this.renewalWorkFlow}
            isLoading={this.state.isLoading}
            text="Renew Registration"
            loadingText="   Validating Zip Code..."
          />
        </footer>
      </Container>
    );
  }

  renderDisplayFees() {
    const { validated } = this.state.validated;
    let clsPriceA = "";
    let clsPriceB = "";
    let clsPrice1 = "";
    let clsPrice2 = "";
    let displayPrice = 0;
    let pViewToggle = "";

    let ftrHdrStep2 = "";

    /*
        What do we need to do?

        if: no price

        then: hide email and name with display none.



    */

    if (parseInt(window.innerHeight) < 720) {
      console.log("INNNNNNY BIATCH");
      ftrHdrStep2 = "hdr-step-title-OFF";
    } else {
      ftrHdrStep2 = "hdr-step-title";
    }

    console.log(
      "THIS IS THE DATA TYPE IN RENDER: ",
      typeof Number(this.state.price)
    );

    console.log("THIS IS THE DATA IN RENDER: ", Number(this.state.price));
    console.log("IsNAN IN RENDER: ", isNaN(Number(this.state.price)));

    if (
      this.state.price ===
        "Please wait, The CA-DMV is Loading the Fees Due Now..." ||
      isNaN(Number(this.state.price))
    ) {
      //clsPrice = "loading-card-fields";
      clsPriceA = "loading-fields";
      clsPriceB = "loading-fields";
      clsPrice1 = "";
      clsPrice2 = "loading-fields";

      console.log(
        "THIS IS THE DATA IN RENDER IF STRING: ",
        Number(this.state.price)
      );

      console.log("THIS IS THE DATA IN RENDER IF STR: ", this.state.price);
      displayPrice = 1;

      // keep p-view-renewal on as is and prep to turn off for toggle
      pViewToggle = "p-view-renewal";
    } else {
      console.log(
        "THIS IS THE DATA IN RENDER IF NUM: ",
        Number(this.state.price)
      );

      console.log(
        "THIS IS THE DATA IN RENDER IF NUM: ",
        Number(this.state.price)
      );

      //clsPrice = "loaded-card-fields";
      clsPriceA = "email-form";
      clsPriceB = "billing-group";
      clsPrice1 = "loading-fields";
      clsPrice2 = "loaded-price";
      displayPrice = this.state.price;

      // turn off p-view and toggle it from email
      // toggle on hover with submit btn maybe?
      pViewToggle = "p-view-renewal-OFF";
    }

    return (
      <Container
        validated={validated}
        className="main-modal"
        //show={this.props.show}
        //onHide={this.props.setModalShow.bind(this, false)}
        size="md"
        //aria-labelledby="contained-modal-title-vcenter"
        //centered
        //scrollable
      >
        <article>
          <h4 className={ftrHdrStep2}>Review Your Fees</h4>

          <p className="p-view-renewal">
            Please review your fees and continue to submit for payment.
          </p>

          <Container className="renewals">
            {/* Start Form for step 1 here!! */}
            {/* onSubmit={ this.handleSubmit } */}
            <Form className="form-renewals" noValidate validated={validated}>
              {/* This is the customer name */}
              <Form.Group controlId="price" className={clsPrice1}>
                <Card
                  size="lg"
                  bg="light"
                  key="6"
                  text="dark"
                  //className={clsPrice1}
                >
                  <Card.Body className="card-object">
                    <Card.Title className="price-card-title">
                      Fees Due
                    </Card.Title>
                    <Card.Text className="price-card-description">
                      {this.state.price}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Form.Group>
              {/* This is the form group taking the animated text price*/}
              <Form.Group controlId="price" className={clsPrice2}>
                <Card
                  size="lg"
                  bg="light"
                  key="6"
                  text="dark"
                  //className={clsPrice1}
                >
                  <Card.Body className="card-object">
                    <Card.Title className="price-card-title">
                      Fees Due
                    </Card.Title>
                    <section className="receipt-print">
                      {this.state.step2Receipt}
                    </section>
                    <article className="price-card-description">
                      <Spring
                        from={{ number: 0 }}
                        to={{ number: Number(displayPrice) }}
                      >
                        {(props) => (
                          <div className="display-price-num">
                            ${" "}
                            {this.roundToPrecision(props.number, 0.05).toFixed(
                              2
                            )}
                          </div>
                        )}
                      </Spring>
                    </article>
                  </Card.Body>
                </Card>
              </Form.Group>

              <Form.Group controlId="email" className={clsPriceA}>
                <Row className="email-field">
                  <Col xs={2} className="email-col-1">
                    <Form.Label>Enter Email</Form.Label>
                  </Col>
                  <Col className="email-col-2">
                    <Form.Control
                      autoFocus
                      size="lg"
                      className="zoom-fields"
                      type="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      onFocus={this.handleFocusPorsche}
                      onBlur={this.handleBlurPorsche}
                      required
                    />
                  </Col>
                </Row>
              </Form.Group>

              {/* This is Billing Name */}
              <Form.Group controlId="billingName" className={clsPriceB}>
                <Row className="billing-name-field">
                  <Col xs={2} className="billing-name-col-1">
                    <Form.Label>Billing Name</Form.Label>
                  </Col>
                  <Col className="billing-name-col-2">
                    <Form.Control
                      type="text"
                      className="zoom-fields"
                      value={this.state.billingName}
                      onChange={this.handleChange}
                      onFocus={this.handleFocusPorsche}
                      onBlur={this.handleBlurPorsche}
                      placeholder="Ex: Name on Credit Card!!!"
                    />
                  </Col>
                </Row>
              </Form.Group>

              <p className={pViewToggle}>
                By submiting your email above, you are agreeing to our terms and
                conditions and you are allowing QuickAutoTags to Authorize your
                credit card in the amount of{" "}
                <strong>${`${this.state.price}`}</strong>, to complete the
                payment of your California DMV Auto Tag Registration Renewal
                Fees.
              </p>
            </Form>
          </Container>
        </article>

        {/* this.props.setModalShow.bind(this, false) */}
        <footer>
          <UiLoadBtn
            block
            size="lg"
            className="renew-btn-views"
            disabled={!this.validateForm()} //FIXME: NEED TO PUT THIS BACK TO GET RID OF MODAL ERROR!!!
            //variant="success"
            onClick={this.renewalWorkFlow}
            isLoading={this.state.isLoading}
            text="Submit Payment"
            loadingText="   Getting DMV Fees..."
          />
        </footer>
      </Container>
    );
  }

  renderPaymentUi() {
    const { validated } = this.state.validated;

    const tokenProps = {
      // below is token info
      userEmvData: this.userEmvData,
      maskfirsttwo: true,
      useexpiry: true,
      usemonthnames: true,
      usecvv: true,
      cardnumbernumericonly: true,
      orientation: "horizontal",
      invalidinputevent: true,
      enhancedresponse: true,
      formatinput: true,
      tokenizewheninactive: true,
      inactivityto: 500,
    };

    return (
      <Container
        validated={validated}
        className="main-modal"
        //show={this.props.show}
        //onHide={this.props.setModalShow.bind(this, false)}
        size="md"
        //aria-labelledby="contained-modal-title-vcenter"
        //centered
        //scrollable
      >
        <article>
          <p className="p-view-renewal" style={{ marginBottom: "0.3em" }}>
            Confirm & Authorize a charge in the amount of{" "}
            <strong>${`${this.state.price}`}</strong> to pay your Renewal Fees
            securely.
          </p>

          <Container className="renewals">
            {/* Start Form for step 1 here!! */}
            {/* onSubmit={ this.handleSubmit } */}

            <Form className="form-renewals" noValidate validated={validated}>
              {/* This is street Addreess */}
              <Form.Group controlId="billingAddress" className="billing-group">
                <Form.Label>Billing Address</Form.Label>
                <Form.Control
                  className="zoom-fields"
                  type="text"
                  value={this.state.billingAddress}
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  placeholder="Ex: 1234 E. Main St."
                />
              </Form.Group>
              <Row className="billing-row-1">
                <Col className="billing-address-2">
                  {/* This is street Addreess2 */}
                  <Form.Group
                    controlId="billingAddress2"
                    className="billing-group"
                  >
                    <Form.Label>Billing Unit</Form.Label>
                    <Form.Control
                      type="text"
                      className="zoom-fields"
                      value={this.state.billingAddress2}
                      onChange={this.handleChange}
                      onFocus={this.handleFocusPorsche}
                      onBlur={this.handleBlurPorsche}
                      placeholder="Ex: Unit 201"
                    />
                  </Form.Group>
                </Col>
                <Col className="billing-city">
                  {/* This is street city */}
                  <Form.Group controlId="billingCity" className="billing-group">
                    <Form.Label>Billing City</Form.Label>
                    <Form.Control
                      type="text"
                      className="zoom-fields"
                      value={this.state.billingCity}
                      onChange={this.handleChange}
                      onFocus={this.handleFocusPorsche}
                      onBlur={this.handleBlurPorsche}
                      placeholder="Ex: Riverside"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="billing-row-2">
                <Col className="billing-state">
                  {/* This is street state */}
                  <Form.Group
                    controlId="billingState"
                    className="billing-group"
                  >
                    <Form.Label>Billing State</Form.Label>
                    <Form.Control
                      as="select"
                      value={this.state.billingState}
                      onChange={this.handleChange}
                      onFocus={this.handleFocusPorsche}
                      onBlur={this.handleBlurPorsche}
                      required
                      pattern="AL|AK|AZ|AR|CA|CO|CT|DE|DC|FL|GA|HI|ID|IL|IN|IA|KS|KY|LA|ME|MD|MA|MI|MN|MS|MO|MT|NE|NV|NH|NJ|NM|NY|NC|ND|OH|OK|OR|PA|RI|SC|SD|TN|TX|UT|VT|VA|WA|WV|WI|WY"
                    >
                      <option>Select</option>
                      <option>AL</option>
                      <option>AK</option>
                      <option>AZ</option>
                      <option>AR</option>
                      <option>CA</option>
                      <option>CO</option>
                      <option>CT</option>
                      <option>DE</option>
                      <option>DC</option>
                      <option>FL</option>
                      <option>GA</option>
                      <option>HI</option>
                      <option>ID</option>
                      <option>IL</option>
                      <option>IN</option>
                      <option>IA</option>
                      <option>KS</option>
                      <option>KY</option>
                      <option>LA</option>
                      <option>ME</option>
                      <option>MD</option>
                      <option>MA</option>
                      <option>MI</option>
                      <option>MN</option>
                      <option>MS</option>
                      <option>MO</option>
                      <option>MT</option>
                      <option>NE</option>
                      <option>NV</option>
                      <option>NH</option>
                      <option>NJ</option>
                      <option>NM</option>
                      <option>NY</option>
                      <option>NC</option>
                      <option>ND</option>
                      <option>OH</option>
                      <option>OK</option>
                      <option>OR</option>
                      <option>PA</option>
                      <option>RI</option>
                      <option>SC</option>
                      <option>SD</option>
                      <option>TN</option>
                      <option>TX</option>
                      <option>UT</option>
                      <option>VT</option>
                      <option>VA</option>
                      <option>WA</option>
                      <option>WV</option>
                      <option>WI</option>
                      <option>WY</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col className="billing-zip">
                  {/* This is billing zip */}
                  <Form.Group
                    controlId="billingZipCode"
                    className="billing-group"
                  >
                    <Form.Label>Billing ZipCode</Form.Label>
                    <Form.Control
                      className="zoom-fields"
                      type="text"
                      value={this.state.billingZipCode}
                      onChange={this.handleChange}
                      onFocus={this.handleFocusPorsche}
                      onBlur={this.handleBlurPorsche}
                      placeholder="Ex: 92509"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <Container style={{ maxHeight: "5em" }}>
              <NativeStackTokenizer
                site="fts"
                port="443"
                tokenProps={tokenProps}
              />
            </Container>
          </Container>
        </article>

        {/* this.props.setModalShow.bind(this, false)
            FIXME: This button needs to trigger the authorize & capture of txn using token
          */}
        <footer>
          <UiLoadBtn
            block
            size="lg"
            className="renew-btn-views-3"
            disabled={!this.validateForm()}
            //variant="success"
            onClick={this.renewalWorkFlow}
            isLoading={this.state.isLoading}
            text="Authorize Credit Card"
            loadingText="   Processing Payment..."
          />
        </footer>
      </Container>
    );
  }

  renderPaymentResponse() {
    const { validated } = this.state.validated;

    return (
      <Container
        validated={validated}
        className="main-modal"
        //show={this.props.show}
        //onHide={this.props.setModalShow.bind(this, false)}
        size="md"
        //aria-labelledby="contained-modal-title-vcenter"
        //centered
        //scrollable
      >
        <article>
          <p
            className="p-view-renewal"
            style={{ marginBottom: "0.3rem", marginTop: "0.6rem" }}
          >
            Please enter your shipping address below to receive your new vehicle
            registration sticker.
          </p>

          <Container className="renewals">
            {/* Start Form for step 1 here!! */}
            {/* onSubmit={ this.handleSubmit } */}
            <Form className="form-renewals" noValidate validated={validated}>
              {/* This is the customer name */}
              <Form.Group
                controlId="paymentReceipt"
                style={{ marginBottom: "0rem" }}
              >
                <Form.Label style={{ marginBottom: "0rem" }}>
                  Fees Paid
                </Form.Label>
                <Form.Control
                  readOnly
                  as="textarea"
                  rows="5"
                  className="mcase-fields"
                  size="lg"
                  value={this.state.paymentReceipt}
                  required
                />
              </Form.Group>
              {/* NOTE: Implemented Google Geocode API to confirm zipCode & city by location */}
              <Form.Group
                controlId="shipToAddress"
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              >
                <Form.Label style={{ marginTop: "0.3rem" }}>
                  Shipping Street Address
                </Form.Label>
                <Geosuggest
                  ref={(el) => (this._geoSuggest = el)}
                  placeholder="Enter your Street Address!"
                  country="us"
                  onSuggestSelect={this.selectSuggestion}
                  location={new google.maps.LatLng(33.996575, -117.4068808)}
                  radius="5000000"
                  required
                />
              </Form.Group>
              {/* This is street Addreess line2 */}
              <Row>
                <Col>
                  <Form.Group controlId="shipToAddressLine2">
                    <Form.Label className="shipping-labels">
                      Suite or Unit
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="zoom-fields"
                      value={this.state.shipToAddressLine2}
                      onChange={this.handleChange}
                      onFocus={this.handleFocusPorsche}
                      onBlur={this.handleBlurPorsche}
                      placeholder="Ex: Unit #101"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  {/* This is shipping zip code */}
                  <Form.Group controlId="shipToZipCode">
                    <Form.Label className="shipping-labels">
                      Shipping ZipCode
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="zoom-fields"
                      value={this.state.shipToZipCode}
                      onChange={this.handleChange}
                      onFocus={this.handleFocusPorsche}
                      onBlur={this.handleBlurPorsche}
                      placeholder="Ex: 92509"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Container>
        </article>

        {/* this.props.setModalShow.bind(this, false) */}
        <footer>
          <UiLoadBtn
            block
            size="lg"
            className="renew-btn-views"
            disabled={!this.validateForm()}
            //variant="success"
            onClick={this.renewalWorkFlow}
            isLoading={this.state.isLoading}
            text="Send Registration"
            loadingText="   Sending Registration..."
          />
        </footer>
      </Container>
    );
  }

  renderConfirmationUi() {
    const { validated } = this.state.validated;

    let pos = "";

    if (this.state.renewalStep === 5) {
      pos = "30%";
    }

    const avrsConfirmPos = {
      transform: `translateY(${pos})`,
    };

    return (
      <Container
        validated={validated}
        className="main-modal"
        //show={this.props.show}
        //onHide={this.props.setModalShow.bind(this, false)}
        size="md"
        //aria-labelledby="contained-modal-title-vcenter"
        //centered
        //scrollable
        style={avrsConfirmPos}
      >
        <article>
          <h4 className="hdr-step-title">Your Payment is Complete</h4>

          <p className="p-view-renewal" style={{ marginBottom: "0.3rem" }}>
            Thank your for renewing your CA DMV vehicle registration online with
            QuickAutoTags.
          </p>

          <Container className="renewals">
            {/* Start Form for step 1 here!! */}
            {/* onSubmit={ this.handleSubmit } */}
            <Form className="form-renewals" noValidate validated={validated}>
              {/* This is the customer name */}
            </Form>
          </Container>
        </article>
        <article className="complete-img-col-lg">
          <Image
            src="https://cdn-assets.quickautotags.com/images/branding/Logo.QAT.png"
            className="App-logo-lg"
          />
        </article>

        {/* this.props.setModalShow.bind(this, false) */}
        <footer>
          <UiLoadBtn
            block
            size="lg"
            className="renew-btn-views"
            disabled={false}
            //variant="success"
            onClick={this.renewalWorkFlow}
            isLoading={this.state.isLoading}
            text="Close"
            loadingText="   Closing Portal..."
          />
        </footer>
      </Container>
    );
  }

  renderDeclineUi() {
    const { validated } = this.state.validated;

    let pos = "";

    if (this.state.renewalStep === 6) {
      pos = "20%";
    }

    const avrsConfirmPos = {
      transform: `translateY(${pos})`,
    };

    return (
      <Container
        validated={validated}
        className="main-modal"
        //show={this.props.show}
        //onHide={this.props.setModalShow.bind(this, false)}
        size="md"
        //aria-labelledby="contained-modal-title-vcenter"
        //centered
        //scrollable
        style={avrsConfirmPos}
      >
        <article>
          <h4 className="hdr-step-title">Your Payment is DECLINED</h4>

          <p className="p-view-renewal" style={{ marginBottom: "0.3rem" }}>
            Your payment was DECLINED or NOT AUTHORIZED. The renewal of your CA
            DMV vehicle registration was not successfully processed. Please
            close this application or call us directly so we can personally
            serve your needs directly.
          </p>

          <Container className="renewals">
            {/* Start Form for step 1 here!! */}
            {/* onSubmit={ this.handleSubmit } */}
            <Form className="form-renewals" noValidate validated={validated}>
              {/* This is the customer name */}
            </Form>
          </Container>
        </article>
        <article className="complete-img-col-lg">
          <Image
            src="https://cdn-assets.quickautotags.com/images/branding/Logo.QAT.png"
            className="App-logo-lg"
          />
        </article>

        {/* this.props.setModalShow.bind(this, false) */}
        <footer>
          <UiLoadBtn
            block
            size="lg"
            className="renew-btn-views"
            disabled={false}
            //variant="success"
            onClick={this.renewalWorkFlow}
            isLoading={this.state.isLoading}
            text="Close"
            loadingText="   Closing Portal..."
          />
        </footer>
      </Container>
    );
  }

  // NOTE: This is being used to get the correct workflow step
  // to render. You have to setState outside of the render
  // "canvas".
  renderForms() {
    let form;

    // console.log("THis is the step: ", this.state.renewalStep);

    if (this.state.renewalStep.toString() === "1") {
      form = this.renderCollectData();
    } else if (this.state.renewalStep.toString() === "2") {
      form = this.renderDisplayFees();
    } else if (this.state.renewalStep.toString() === "3") {
      form = this.renderPaymentUi();
    } else if (this.state.renewalStep.toString() === "4") {
      form = this.renderPaymentResponse();
    } else if (this.state.renewalStep.toString() === "5") {
      form = this.renderConfirmationUi();
    } else if (this.state.renewalStep.toString() === "6") {
      form = this.renderDeclineUi();
    }

    return form;
  }

  render() {
    let pos = "";

    if (parseInt(window.innerWidth) >= 1360) {
      if (this.state.renewalStep === 1) {
        if (!this.state.isFocused && this.props.view === "avrs-hdr") {
          pos = "9%";
        } else if (this.state.isFocused && this.props.view === "avrs-hdr") {
          pos = "3%";
        }

        if (!this.state.isFocusedPorsche && this.props.view === "porsche") {
          pos = "12%";
        } else if (
          this.state.isFocusedPorsche &&
          this.props.view === "porsche"
        ) {
          pos = "9%";
        }
      } else if (this.state.renewalStep === 2) {
        if (!this.state.isFocused && this.props.view === "avrs-hdr") {
          pos = "6%";
        } else if (this.state.isFocused && this.props.view === "avrs-hdr") {
          pos = "3%";
        }

        if (!this.state.isFocusedPorsche && this.props.view === "porsche") {
          pos = "6%";
        } else if (
          this.state.isFocusedPorsche &&
          this.props.view === "porsche"
        ) {
          pos = "3%";
        }
      } else if (this.state.renewalStep === 3) {
        if (!this.state.isFocused && this.props.view === "avrs-hdr") {
          pos = "1%";
        } else if (this.state.isFocused && this.props.view === "avrs-hdr") {
          pos = "-1%";
        }

        if (!this.state.isFocusedPorsche && this.props.view === "porsche") {
          pos = "1%";
        } else if (
          this.state.isFocusedPorsche &&
          this.props.view === "porsche"
        ) {
          pos = "-1%";
        }
      } else if (this.state.renewalStep === 4) {
        if (!this.state.isFocused && this.props.view === "avrs-hdr") {
          pos = "0%";
        } else if (this.state.isFocused && this.props.view === "avrs-hdr") {
          pos = "-2%";
        }

        if (!this.state.isFocusedPorsche && this.props.view === "porsche") {
          pos = "0%";
        } else if (
          this.state.isFocusedPorsche &&
          this.props.view === "porsche"
        ) {
          pos = "-2%";
        }
      }
    } else if (parseInt(window.innerWidth) < 1360) {
      if (this.state.renewalStep === 1) {
        if (!this.state.isFocused && this.props.view === "avrs-hdr") {
          pos = "6%";
        } else if (this.state.isFocused && this.props.view === "avrs-hdr") {
          pos = "3%";
        }

        if (!this.state.isFocusedPorsche && this.props.view === "porsche") {
          pos = "6%";
        } else if (
          this.state.isFocusedPorsche &&
          this.props.view === "porsche"
        ) {
          pos = "3%";
        }
      } else if (this.state.renewalStep === 2) {
        if (!this.state.isFocused && this.props.view === "avrs-hdr") {
          pos = "3%";
        } else if (this.state.isFocused && this.props.view === "avrs-hdr") {
          pos = "0%";
        }

        if (!this.state.isFocusedPorsche && this.props.view === "porsche") {
          pos = "3%";
        } else if (
          this.state.isFocusedPorsche &&
          this.props.view === "porsche"
        ) {
          pos = "0%";
        }
      } else if (this.state.renewalStep === 3) {
        if (!this.state.isFocused && this.props.view === "avrs-hdr") {
          pos = "1%";
        } else if (this.state.isFocused && this.props.view === "avrs-hdr") {
          pos = "-1%";
        }

        if (!this.state.isFocusedPorsche && this.props.view === "porsche") {
          pos = "1%";
        } else if (
          this.state.isFocusedPorsche &&
          this.props.view === "porsche"
        ) {
          pos = "-1%";
        }
      } else if (this.state.renewalStep === 4) {
        if (!this.state.isFocused && this.props.view === "avrs-hdr") {
          pos = "0%";
        } else if (this.state.isFocused && this.props.view === "avrs-hdr") {
          pos = "-2%";
        }

        if (!this.state.isFocusedPorsche && this.props.view === "porsche") {
          pos = "0%";
        } else if (
          this.state.isFocusedPorsche &&
          this.props.view === "porsche"
        ) {
          pos = "-2%";
        }
      }
    }

    const avrsColRightPos = {
      transform: `translateY(${pos})`,
    };

    return (
      <div className="Renewals" style={avrsColRightPos}>
        {this.renderForms()}
      </div>
    );
  }
}
