import React from "react";
import { Switch, Route } from "react-router-dom";

import AppliedRoute from "./components/AppliedRoute";
import Home from "./containers/Home";
import Page404 from "./components/Page404";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
{
  /*
import AiResearch from "./components/AiResearch";
import NasaChallenger from "./components/NasaChallenger";
import BtcPriceModel from "./components/BtcPriceModel";
import BlogArchive from "./components/BlogArchive";
import MacOsDevSetup from "./components/MacOsDevSetup";
*/
}

//import TermsAndConditions from "./components/TermsAndConditions";
//import PrivacyPolicy from "./components/PrivacyPolicy";
//import Support from "./components/Support";
//import AuthenticatedRoute from "./components/AuthenticatedRoute";
//import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default ({ childProps }) => (
  <Switch>
    {/* This is our home page route for the main landing page to the app */}
    <AppliedRoute path="/" exact component={Home} props={childProps} />

    {/* This is the terms-conditions Route */}
    <AppliedRoute
      path="/terms-conditions"
      exact
      component={TermsAndConditions}
      props={childProps}
    />

    {/* This is the privacy-policy Route */}
    <AppliedRoute
      path="/privacy-policy"
      exact
      component={PrivacyPolicy}
      props={childProps}
    />

    {/*
    <AppliedRoute
      path="/ai-machine-learning-research"
      exact
      component={AiResearch}
      props={childProps}
    />

    <AppliedRoute
      path="/ai-machine-learning-research/nasa-challenger"
      exact
      component={NasaChallenger}
      props={childProps}
    />

    <AppliedRoute
      path="/ai-machine-learning-research/btc-pricing-models"
      exact
      component={BtcPriceModel}
      props={childProps}
    />

    <AppliedRoute
      path="/software-revolt"
      exact
      component={BlogArchive}
      props={childProps}
    />

    <AppliedRoute
      path="/software-revolt/mac-os-dev-setup"
      exact
      component={MacOsDevSetup}
      props={childProps}
    />

  */}

    {/* This is the terms-conditions Route
                                   <AppliedRoute path="/terms-conditions"
                                     exact
                                     component={ TermsAndConditions }
                                     props={ childProps } />

                                   { /* This is the privacy-policy Route 
                                   <AppliedRoute path="/privacy-policy"
                                     exact
                                     component={ PrivacyPolicy }
                                     props={ childProps } />

                                   { /* This is the support Route 
                                   <AppliedRoute path="/support"
                                     exact
                                     component={ Support }
                                     props={ childProps } />

                                   { /* This route will catch all unmatched routes && MUST BE LAST!!! */}
    <Route component={Page404} />
  </Switch>
);
